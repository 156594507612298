import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "./TrackEvaluation.css";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import "../../styles/consistent-theme.scss";
import SUPHeader from "../../components/Shared/Header/SupHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SMLToggleButton from "../../components/Shared/SMLToggleButton/SMLToggleButton";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import SMLTextArea from "../../components/Shared/SMLTextArea/SMLTextArea";
import SubmitTrackPopup from "../SubmitTrackPopups/SubmitTrackPopup";
import {
  RESET_ASSIGNED_SUPERVISOR,
  RESET_DOWNLOAD_TRACK,
  RESET_GET_FEEDBACK,
  RESET_GET_SINGLE_TRACK,
  RESET_POST_FEEDBACK_ASSIGNED,
  RESET_PUT_FEEDBACK_ASSIGNED,
  RESET_PUT_FEEDBACK_MS_ASSIGNED,
  RESET_PUT_FINAL_FEEDBACK_MS_ASSIGNED,
} from "../../redux/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeft from "../../assets/Icons/arrow-left.svg";
import RateBar from "../../components/UI/RateBar/RateBar";
import {
  getFeedbackRequest,
  postFeedbackRequest,
  putFeedbackMsRequest,
  putFeedbackRequest,
} from "../../redux/actions";
import Union from "../../assets/Images/Union.png";
import { Bars } from "react-loader-spinner";

const TrackEvaluation = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMaster = localStorage.getItem("isMaster");
  const assigned_supervisor_id = location.state?.assignedSupId;
  const meId = useSelector((state) => state.sml.allArtistFetched?.artists?._id);
  const status = location.state?.status;
  const [rating, setRatings] = useState({
    production: 0,
    vocals: 0,
    lyrics: 0,
    dynamics: 0,
    build: 0,
    editor_hit_points: 0,
    stop_downs: 0,
    back_end: 0,
  });
  const options = [
    "TV",
    "Film",
    "Trailers & Film/TV Marketing",
    "Commercials",
    "Video Game",
    "Indie Label",
  ];
  const [syncPotential, setSyncPotential] = useState(true);
  const [suggestedTargets, setSuggestedTargets] = useState([]);
  const [notes, setNotes] = useState("");
  const [notesError, setNotesError] = useState("");
  const [masterNotesError, setMasterNotesError] = useState("");
  const [fieldNotesError, setFieldNotesError] = useState("");
  const [targetError, setTargetError] = useState("");
  const [showError, setShwoError] = useState(false);
  const [showHandleSubmitTrack, setShowHandleSubmitTrack] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [masterNotes, setMasterNotes] = useState("");
  const [feedbackId, setFeedbackId] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const feedback = useSelector((state) => state.getFeedback?.feedback);

  useEffect(() => {
    dispatch({ type: RESET_PUT_FEEDBACK_MS_ASSIGNED });
    dispatch({ type: RESET_PUT_FEEDBACK_ASSIGNED });
    if (meId && feedback && feedback.length > 0) {
      const supFeedback = feedback[0].master_feedback
        ? feedback[0].master_feedback
        : feedback[0].supervisor_feedback;

      setFeedbackId(feedback[0]._id);
      setDownloaded(supFeedback?.download_track);
      setRatings(supFeedback?.ratings);
      if (supFeedback?.ratings) {
        setRatings((prevRatings) => ({
          ...prevRatings,
          ...supFeedback.ratings,
        }));
      }
      setSyncPotential(supFeedback?.sync_potential);
      setSuggestedTargets(supFeedback?.suggested_targets);
      const notesFeedback = feedback[0]?.master_feedback?.ratings
        ? feedback[0]?.master_feedback?.ratings
        : feedback[0].supervisor_feedback?.ratings;

      const initialShowNotes = {
        production: !!supFeedback?.ratings?.production_notes,
        vocals: !!supFeedback?.ratings?.vocals_notes,
        lyrics: !!supFeedback?.ratings?.lyrics_notes,
        dynamics: !!supFeedback?.ratings?.dynamics_notes,
        build: !!supFeedback?.ratings?.build_notes,
        editor_hit_points: !!supFeedback?.ratings?.editor_hit_points_notes,
        stop_downs: !!supFeedback?.ratings?.stop_downs_notes,
        back_end: !!supFeedback?.ratings?.back_end_notes,
      };
      setShowNotes(initialShowNotes);
      setFieldNotes({
        production: notesFeedback?.production_notes,
        vocals: notesFeedback?.vocals_notes,
        lyrics: notesFeedback?.lyrics_notes,
        dynamics: notesFeedback?.dynamics_notes,
        build: notesFeedback?.build_notes,
        editor_hit_points: notesFeedback?.editor_hit_points_notes,
        stop_downs: notesFeedback?.stop_downs_notes,
        back_end: notesFeedback?.back_end_notes,
      });
      let masterFeedbackPresent = true;
      let supervisorFeedbackPresent = true;

      // Check for undefined values
      if (feedback[0].master_feedback === undefined) {
        masterFeedbackPresent = false; // Flag for master feedback
      }
      if (feedback[0].supervisor_feedback === undefined) {
        supervisorFeedbackPresent = false; // Flag for supervisor feedback
      }
      setNotes(
        isMaster === "true" && assigned_supervisor_id === meId
          ? feedback[0].master_feedback?.notes
          : feedback[0]?.supervisor_feedback?.notes
      );
      meId &&
        setMasterNotes(
          assigned_supervisor_id === meId
            ? masterFeedbackPresent
              ? feedback[0]?.master_feedback?.notes
              : feedback[0]?.supervisor_feedback?.notes
            : feedback[0]?.master_feedback?.notes
        );
    }
  }, [feedback, meId, assigned_supervisor_id]);

  useEffect(() => {
    dispatch({ type: RESET_GET_FEEDBACK });
    dispatch(getFeedbackRequest(trackId));
  }, []);

  const currentSupId = useSelector(
    (state) => state.sml.allArtistFetched.artists?._id
  );
  const handleRatingChange = (field, value) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [field]: value,
    }));
  };

  const handleSyncPotentialChange = () => {
    setSyncPotential(!syncPotential);
  };

  const handleClickOption = (option) => {
    setSuggestedTargets((prevTargets) =>
      prevTargets.includes(option)
        ? prevTargets.filter((target) => target !== option)
        : [...prevTargets, option]
    );
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const { trackId } = useParams();

  const handleSubmitTrackPopup = () => {
    if (
      notesError === "" &&
      targetError === "" &&
      masterNotesError === "" &&
      fieldNotesError === ""
    ) {
      setShwoError(false);
      dispatch({ type: RESET_POST_FEEDBACK_ASSIGNED });
      dispatch({ type: RESET_PUT_FEEDBACK_ASSIGNED });
      dispatch({ type: RESET_PUT_FEEDBACK_MS_ASSIGNED });
      const trackEvaluationData = {
        trackId: trackId,
        supervisor_feedback: {
          ratings: rating,
          sync_potential: syncPotential,
          suggested_targets: suggestedTargets,
          notes: notes,
          download_track: true,
        },
      };
      setShowHandleSubmitTrack(true);
    } else {
      setShwoError(true);
    }
  };
  const newId = useSelector((state) => state.postFeedback?.assigned?._id); //
  const handleSaveClick = () => {
    //if already posted a feedback
    if (isMaster === "false") {
      if (newId || feedbackId) {
        if (assigned_supervisor_id === meId) {
          const wholedata = {
            feedbackId: newId ? newId : feedbackId,
            track_id: trackId,
            isfinal: false,
            supervisor_feedback: {
              ratings: rating || [],
              fieldNotes: fieldNotes,
              sync_potential: syncPotential,
              suggested_targets: suggestedTargets,
              notes: notes,
            },
          };
          dispatch(putFeedbackRequest(wholedata));
        }
      }
      //if not posted a feedback yet
      else {
        const wholedata = {
          track_id: trackId,
          isfinal: false,
          supervisor_feedback: {
            ratings: rating || [],
            fieldNotes: fieldNotes,
            sync_potential: syncPotential,
            suggested_targets: suggestedTargets,
            notes: notes,
          },
        };
        dispatch(postFeedbackRequest(wholedata));
      }
    } else {
      if (assigned_supervisor_id !== meId) {
        const wholedata = {
          feedbackId: feedbackId,
          track_id: trackId,
          isfinal: false,
          supervisor_feedback: {
            ratings: rating || [],
            fieldNotes: fieldNotes,
            sync_potential: syncPotential,
            suggested_targets: suggestedTargets,
            notes: masterNotes,
          },
        };
        dispatch(putFeedbackMsRequest(wholedata));
      } else {
        if (feedbackId || newId) {
          const wholedata = {
            feedbackId: newId ? newId : feedbackId,
            track_id: trackId,
            isfinal: false,
            supervisor_feedback: {
              ratings: rating || [],
              fieldNotes: fieldNotes,
              sync_potential: syncPotential,
              suggested_targets: suggestedTargets,
              notes: masterNotes,
            },
          };
          dispatch(putFeedbackMsRequest(wholedata));
        } else {
          const wholedata = {
            feedbackId: newId ? newId : feedbackId,
            track_id: trackId,
            isfinal: false,
            supervisor_feedback: {
              ratings: rating || [],
              fieldNotes: fieldNotes,
              sync_potential: syncPotential,
              suggested_targets: suggestedTargets,
              notes: masterNotes,
            },
          };
          dispatch(postFeedbackRequest(wholedata));
        }
      }
    }
 
  };
 
  const postedFeedback = useSelector((state) => state.postFeedback?.assigned);
  const putFeedbackSave = useSelector((state) => state.putFeedback?.assigned);
  const putFeedbackMsSave = useSelector(
    (state) => state.putFeedbackMs?.assigned
  );
  useEffect(() => {
    if (postedFeedback || putFeedbackSave || putFeedbackMsSave) {
      setIsSaved(true);
    }
  }, [postedFeedback, putFeedbackSave, putFeedbackMsSave]);

  useEffect(() => {
    if (isSaved) {
      const timer = setTimeout(() => {
        setIsSaved(false);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [isSaved]);

  const postFeedbackLoading1 = useSelector(
    (state) => state.postFeedback?.isLoading
  );
  const putFeedbackMsLoading1 = useSelector(
    (state) => state.putFeedbackMs?.isLoading
  );
  const putFeedbackLoading1 = useSelector(
    (state) => state.putFeedback?.isLoading
  );
  const handleCloseSubmitTrack = () => {
    dispatch({ type: RESET_POST_FEEDBACK_ASSIGNED });
    dispatch({ type: RESET_PUT_FEEDBACK_ASSIGNED });
    dispatch({ type: RESET_PUT_FEEDBACK_MS_ASSIGNED });
    dispatch({ type: RESET_PUT_FINAL_FEEDBACK_MS_ASSIGNED });
    dispatch({ type: RESET_DOWNLOAD_TRACK });
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    setShowHandleSubmitTrack(false);
  };
  const handleCloseSubmitTrackPopup = () => {
    dispatch({ type: RESET_POST_FEEDBACK_ASSIGNED });
    dispatch({ type: RESET_PUT_FEEDBACK_ASSIGNED });
    dispatch({ type: RESET_PUT_FEEDBACK_MS_ASSIGNED });
    dispatch({ type: RESET_PUT_FINAL_FEEDBACK_MS_ASSIGNED });
    dispatch({ type: RESET_DOWNLOAD_TRACK });
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    navigate("/supervisorDashboard");
  };

  const handleChangePages = () => {
    dispatch({ type: RESET_GET_FEEDBACK });
    dispatch({ type: RESET_GET_SINGLE_TRACK });
    navigate(-1);
  };

  const fields = [
    { name: "production", label: "Production" },
    { name: "vocals", label: "Vocals" },
    { name: "lyrics", label: "Lyrics" },
    { name: "dynamics", label: "Dynamics" },
    { name: "build", label: "Build" },
    { name: "editor_hit_points", label: "Editor Hit Points" },
    { name: "stop_downs", label: "Stop-downs" },
    { name: "back_end", label: "Back End" },
  ];
  const [showNotes, setShowNotes] = useState({
    production: false,
    vocals: false,
    lyrics: false,
    dynamics: false,
    build: false,
    editor_hit_points: false,
    stop_downs: false,
    back_end: false,
  });

  const [fieldNotes, setFieldNotes] = useState({
    production: "",
    vocals: "",
    lyrics: "",
    dynamics: "",
    build: "",
    editor_hit_points: "",
    stop_downs: "",
    back_end: "",
  });

  const showingFields = [
    "Production",
    "Vocals",
    "Lyrics",
    "Dynamics",
    "Build",
    "Editor Hit Points",
    "Stop-downs",
    "Back End",
  ];
  const handleToggleNotes = (field) => {
    setShowNotes((prevShowNotes) => {
      const updatedShowNotes = {
        ...prevShowNotes,
        [field]: !prevShowNotes[field],
      };

      // If notes for the field are being hidden, clear the corresponding note
      if (!updatedShowNotes[field]) {
        setFieldNotes((prevFieldNotes) => ({
          ...prevFieldNotes,
          [field]: "", // Clear the note for the field when toggling off
        }));
      }

      return updatedShowNotes;
    });
  };
  function containsXSS(input) {
    const xssPattern =
      /<\s*\/?\s*([a-z][a-z0-9]*)\b[^>]*>(?:[^<]*|<(?!\/?\1\b)[^<]*>)*<\/\1>/gi;
    return xssPattern.test(input);
  }
  const handleFieldNotesChange = (field, value) => {
    setFieldNotes((prevFieldNotes) => ({
      ...prevFieldNotes,
      [field]: value,
    }));
    if (containsXSS(value)) {
      setFieldNotesError(
        `Input in ${field} contains potentially harmful script`
      );
    }
  };

  useEffect(() => {
    if (isMaster === "false") {
      if (notes === "") {
        setNotesError("notes is required");
      } else if (containsXSS(notes)) {
        setNotesError("Input contains potentially harmful script");
      } else {
        setNotesError("");
      }
    } else if (isMaster === "true") {
      if (masterNotes === "" || masterNotes === undefined) {
        setMasterNotesError("Final note to artist is required");
      } else if (containsXSS(masterNotes)) {
        setMasterNotesError("Input contains potentially harmful script");
      } else {
        setMasterNotesError("");
      }
    }
    let fieldNotesErrors = ""; // Initialize an empty error string

    Object.keys(fieldNotes).forEach((key) => {
      if (containsXSS(fieldNotes[key])) {
        fieldNotesErrors = `Input in ${key} contains potentially harmful script`;
        console.log("setting error");
      }
    });

    // Update the state only once after the iteration
    if (fieldNotesErrors) {
      setFieldNotesError(fieldNotesErrors); // Set the accumulated error
    } else {
      setFieldNotesError(""); // Clear the error if no issues are found
    }
    if (!suggestedTargets.length > 0) {
      setTargetError("suggested targets is required");
    } else {
      setTargetError("");
    }
  }, [notes, suggestedTargets, masterNotes, fieldNotes, setFieldNotes]);
  return (
    <div className="vh-100" style={{ overflow: "auto", color: "#333" }}>
      {(postFeedbackLoading1 ||
        putFeedbackMsLoading1 ||
        putFeedbackLoading1) && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <div style={{ backgroundColor: "#f8f9fa" }}>
        {!props.unprotected && <SUPHeader />}
        <div className="track-bg-main-divs mt-3 ">
          <Row className="mb-3">
            <Col>
              <div className="trackEvalHeaderBox">
                <img
                  src={ArrowLeft}
                  alt="Go Back"
                  onClick={handleChangePages}
                />
                <SMLLabel style="title1" value="Track Evaluation" />
              </div>
            </Col>
          </Row>
        </div>

        <div
          className="track-bg-main-divs track-rate-container mt-3 mb-3"
          style={{ backgroundColor: "#ffffff" }}
        >
          <Row className="mb-3">
            <Col>
              <SMLLabel style="title1" value="Please Rate The Following" />
            </Col>
          </Row>
          {fields.map((field, index) => (
            <div key={index} className="my-4">
              <Row>
                <Col>
                  <SMLLabel value={field.label} style="title2" />
                </Col>
              </Row>
              <div className="ratebar">
                <SMLLabel value={rating?.[field?.name]} />
                <Row className="track-rate-slider-div">
                  <Col className="col-12">
                    <RateBar
                      value={rating?.[field?.name]}
                      onChange={(value) =>
                        handleRatingChange(field.name, value)
                      }
                    />
                    <div>
                      <div className="notes-btn">
                        <SMLButton
                          valueStyle="Button2"
                          style="NotesBtn"
                          textColor={"#97180C"}
                          value={"Add Note"}
                          handleClickEvent={() => handleToggleNotes(field.name)}
                        />
                      </div>
                      {showNotes[field.name] && (
                        <textarea
                          className="notes-text"
                          onChange={(e) =>
                            handleFieldNotesChange(field.name, e.target.value)
                          }
                        >
                          {fieldNotes[field.name]}
                        </textarea>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ))}
          {fieldNotesError && (
            <SMLLabel style="error" value={fieldNotesError} />
          )}
        </div>

        <div
          className="track-bg-main-divs track-rate-container mt-3 mb-3"
          style={{ backgroundColor: "#ffffff" }}
        >
          <Row>
            <Col>
              <SMLLabel style="title1" value="Please Answer The Following" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <span className="sub-subtitle2">
                Please be as honest with your evaluation as you can. This
                section will only be seen by Sync Music Lab and will be taken
                into consideration when sending feedback to the artist.
              </span>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="trackEvalBtnBox">
              <SMLLabel
                value="Does this track have potential for sync?"
                style="title2"
              />
              <SMLToggleButton
                value="Yes"
                checked={syncPotential}
                onChange={handleSyncPotentialChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SMLLabel
                value="Suggested targets for track placement opportunities"
                style="title2"
              />
            </Col>
          </Row>
          <Row className="position-relative">
            {
              <Col>
                
                {options.map((target, index) => (
                  <SMLOptionQuestion
                    key={index}
                    type="button"
                    style={
                      suggestedTargets.includes(target)
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleClickOption(target)}
                    value={target}
                  />
                ))}
                {/* )} */}
              </Col>
            }
            {targetError && showError && (
              <span
                style={{
                  color: "#ff4405",
                }}
              >
                {targetError}
              </span>
            )}
          </Row>
          {((meId === assigned_supervisor_id && isMaster === "false") ||
            (meId !== assigned_supervisor_id && isMaster === "true")) && (
            <>
              <Row className="mt-2">
                <Col>
                  <SMLLabel value="Supervisor Notes" style="title2" />
                </Col>
              </Row>
              <Row className="position-relative">
                {assigned_supervisor_id !== meId &&
                isMaster === "true" &&
                status !== "in_review" ? (
                  <SMLLabel value={notes} style="subtitle3" />
                ) : (
                  <SMLTextArea value={notes} onChange={handleNotesChange} />
                )}
                {notesError && showError && (
                  <span
                    style={{
                      color: "#ff4405",
                    }}
                  >
                    {notesError}
                  </span>
                )}
              </Row>
            </>
          )}
          {isMaster === "true" && status !== "in_review" && downloaded && (
            <Row className="mt-3">
              <Col className="trackEvalBtnBox">
                <img src={Union} className="" />
                <SMLLabel value="Track Certified" style="title2" />
              </Col>
            </Row>
          )}
          {isMaster === "true" &&
            (status === "in_review" || "feedback_submitted") && (
              <>
                <Row className="mt-4">
                  <Col>
                    <SMLLabel value="Final Note to Artist" style="title2" />
                  </Col>
                </Row>
                <Row className="position-relative final-note">
                  <SMLLabel
                    value="This will be viewed by the artist as an overall summary of the track evaluation from Sync Music Lab. Write down any thoughts, notes and advice you may have for the artist to take the track to success."
                    style="title3"
                  />

                  <SMLTextArea
                    value={masterNotes}
                    onChange={(e) => setMasterNotes(e.target.value)}
                  />
                </Row>
                {masterNotesError && showError && (
                  <span
                    style={{
                      color: "#ff4405",
                    }}
                  >
                    {masterNotesError}
                  </span>
                )}
              </>
            )}
        </div>

        <div className="track-bg-main-divs my-3">
          {isSaved && (
            <div
              className="d-flex justify-content-end mr-3"
              style={{
                marginRight: "10rem",
                marginBottom: "1rem",
                color: "green",
              }}
            >
              Saved successfully!
            </div>
          )}
          <div className="d-flex align-items-center justify-content-end gap-2">
            <div className="">
              <SMLButton
                style="Button5"
                value="Save"
                valueStyle="text-color"
                handleClickEvent={() => handleSaveClick()}
              />
            </div>

            <div className="">
              <SMLButton
                style="SMLButton1"
                value="Submit Evaluation"
                handleClickEvent={() => handleSubmitTrackPopup()}
              />
              {showHandleSubmitTrack && (
                <div className="handleSubmitTrack-popup">
                  <SubmitTrackPopup
                    feedbackId={newId !== undefined ? newId : feedbackId}
                    trackId={trackId}
                    ratings={rating}
                    fieldNotes={fieldNotes}
                    syncPotential={syncPotential}
                    suggestedTargets={suggestedTargets}
                    notes={notes}
                    onClose={handleCloseSubmitTrack}
                    onClosePopup={handleCloseSubmitTrackPopup}
                    assignedSupId={assigned_supervisor_id}
                    masterNotes={masterNotes}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackEvaluation;
