import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;

export const updatePaymentMethodApi = async (data) => {
  try {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }

    const url = `${BASE_URL}/subscriptions/${data.subscriptionId}/payment-methods`;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    
    const response = await api.put(url, { paymentMethodId: data.paymentMethodId }, config);
    return response.data;
  } catch (error) {
    console.error("error in updating payment method", error.message);
    throw error;
  }
};