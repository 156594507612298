import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../../components/Shared/SMLButton/SMLButton";
import playIcon from "../../../assets/Images/playIcon.svg";
import pauseIcon from "../../../assets/Images/pauseIcon.svg";
import { ExternalLink, Clock4 } from "lucide-react";
import "./TrackSection.css";
import { useDispatch, useSelector } from "react-redux";
import { assignSuperVisorRequest } from "../../../redux/actions";
import { useNavigate } from "react-router-dom";
import { RESET_ASSIGNED_SUPERVISOR } from "../../../redux/actions/actionTypes";
import { Tooltip } from "react-tooltip";
import DurationFormatter from "../DurationFormatter/DurationFormatter";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const TrackSection = ({
  track,
  handleTrackFeedback,
  trackImg,
  audioSrc,
  mediaRef,
  handleTimeUpdate,
  handleTogglePlay,
  isPlaying,
  progress,
  handleShowAssignPopup,
}) => {
  const trackID = track._id;
  const status = track.status.code;
  const isMaster = localStorage.getItem("isMaster");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentSup = useSelector(
    (state) => state.sml.allArtistFetched?.artists?._id
  );

  const handleAssignMyslef = (trackID) => {
    dispatch(
      assignSuperVisorRequest({ trackId: trackID, supervisor: currentSup })
    );
  };

  const assigned = useSelector((state) => state.assignSupervisor.assigned);

  useEffect(() => {
    if (assigned && Object.keys(assigned).length > 0 && isMaster === "false") {
      navigate(`/TrackFeedback/${trackID}`);
      dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    }
  }, [assigned]);

  const formatDate = (isoDateStr) => {
    if (!isoDateStr) return "0m";

    try {
      const date = dayjs(isoDateStr);
      const now = dayjs();
      const diffInDays = now.diff(date, "day");
      const diffInHours = now.diff(date, "hour");
      const diffInMinutes = now.diff(date, "minute");

      if (diffInDays > 0) {
        return `${diffInDays}d`;
      } else if (diffInHours > 0) {
        return `${diffInHours}h`;
      } else if (diffInMinutes > 0) {
        return `${diffInMinutes}m`;
      } else {
        return "0m";
      }
    } catch (error) {
      console.error("Date formatting error:", error);
      return "0m";
    }
  };

  const MetadataTag = ({ children, style = {} }) => (
    <span
      style={{
        backgroundColor: "#f8f9fa",
        color: "#6c757d",
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
        marginRight: "8px",
        display: "inline-flex",
        alignItems: "center",
        ...style,
      }}
    >
      {children}
    </span>
  );

  const handleExternalLink = (e, trackId) => {
    e.stopPropagation(); // Prevent main track click
    window.open(`/TrackFeedback/${trackId}`, "_blank");
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleTrackFeedback(trackID, status);
        }}
        className="trackSectionMain"
        style={{ padding: "12px 10px" }}
      >
        <Row className="trackRow" style={{ margin: 0 }}>
          <Col
            style={{
              maxWidth: "111px",
              margin: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="video-player">
              <img
                src={trackImg}
                className="trackImg"
                width={82}
                height={82}
                alt="Track"
              />
              <audio
                preload="none"
                ref={mediaRef}
                src={audioSrc}
                onTimeUpdate={handleTimeUpdate}
                style={{ width: "100%" }}
                onClick={handleTogglePlay}
              />
              <div className="controls">
                {isPlaying ? (
                  <img src={pauseIcon} alt="Pause" onClick={handleTogglePlay} />
                ) : (
                  <img src={playIcon} alt="Play" onClick={handleTogglePlay} />
                )}
              </div>
            </div>
          </Col>

          <Col className="mt-3 track-name">
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                >
                  <div className="trackTitleSup">
                    <h3
                      data-tooltip-id="trackTitle-player"
                      data-tooltip-content={track?.title}
                      // className={}
                    >
                      {track?.title}
                    </h3>
                    <Tooltip
                      id="trackTitle-player"
                      multiline="true"
                      place="top"
                    />
                  </div>
                  <MetadataTag>
                    <DurationFormatter seconds={track?.duration} />
                  </MetadataTag>
                  {track.original_track_id && (
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        backgroundColor: "#fff3ed",
                        borderRadius: "12px",
                        overflow: "hidden",
                      }}
                    >
                      <span
                        style={{
                          color: "#ff4405",
                          padding: "2px 8px",
                          fontSize: "12px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        Revision: {track.revision_number || 1}
                      </span>

                      <a
                        href={`/TrackFeedback/${track.original_track_id}`}
                        onClick={(e) =>
                          handleExternalLink(e, track.original_track_id)
                        }
                        style={{
                          color: "#6c757d",
                          padding: "2px 8px",
                          height: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          borderLeft: "1px solid rgba(255,68,5,0.2)",
                          backgroundColor: "#fff3ed",
                        }}
                      >
                        <ExternalLink size={14} />
                      </a>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <div
                className="d-flex align-items-center gap-4"
                style={{ marginTop: "4px" }}
              >
                <div>
                  <SMLLabel
                    style="sub-subtitle2"
                    value={track?.artist_name || "Artist"}
                  />
                </div>
                {/* <div className="artist-plan-type partner">BASIC</div> */}
              </div>
            </Row>
          </Col>

          <Col
            className="mt-3 col-auto"
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="sub-subtitle2" value={"Genre"} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="title" value={track.genre} />
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            className="mt-3 col-auto"
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="sub-subtitle2 float-right" value="Status" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <div className="d-xl-none">
                    {track.status.label.length > 10 ? (
                      <>
                        {track?.status.label.substring(0, 10)}
                        <a
                          data-tooltip-id="trackStatusXL"
                          place="top"
                          effect="solid"
                          data-tooltip-content={track?.status.label}
                          data-tooltip-place="bottom"
                          multiline="true"
                        >
                          ....
                        </a>
                        <Tooltip id="trackStatusXL" />
                      </>
                    ) : (
                      track.status.label
                    )}
                  </div>
                  <div className="d-none d-xl-block">
                    <SMLLabel
                      style={{ float: "right" }}
                      value={track.status.label}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          {isMaster === "true" && track?.assigned_supervisor && (
            <>
              <Col
                className="mt-3 col-auto"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  maxWidth: "250px",
                }}
              >
                <Row>
                  <Col className="d-flex align-items-end justify-content-end">
                    <div>
                      <SMLLabel style="sub-subtitle2" value={"Assigned to"} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex align-items-end justify-content-end">
                    <div>
                      <SMLLabel
                        style="title"
                        value={
                          track?.assigned_supervisor?.name
                            ? track.assigned_supervisor.name
                            : status !== "feedback_submitted"
                            ? "Myself"
                            : ""
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                className="mt-3 col-auto d-flex justify-content-start align-items-center gap-2"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  minWidth: "4rem",
                }}
              >
                <SMLLabel style="sub-subtitle2" value={<Clock4 />} />
                <SMLLabel
                  style="sub-subtitle3"
                  value={formatDate(track?.assigned_supervisor?.date_assigned)}
                />
              </Col>
            </>
          )}

          {!track?.assigned_supervisor?.name &&
            isMaster === "true" &&
            status === "submitted" && (
              <Col
                className="mt-3 col-auto"
                style={{ marginLeft: "20px", marginRight: "20px" }}
              >
                <Row>
                  <SMLButton
                    style="SMLAssignBtn"
                    value="Assign"
                    handleClickEvent={(e) => {
                      e.stopPropagation();
                      handleShowAssignPopup(trackID);
                    }}
                    valueStyle="Button2"
                    // textColor={"#ff4405"}
                  />
                </Row>
              </Col>
            )}
        </Row>
      </div>
    </>
  );
};

export default TrackSection;
