import React, { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import "./NotifPanel.css";
import notification from "../../../assets/Images/notifications.svg";
import readNotification from "../../../assets/Images/readNotification.svg";
import SMLLabel from "../../Shared/SMLLabel/SMLLabel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationRequest,
  updateNotificationRequest,
} from "../../../redux/actions";
import { BellOff, Loader } from "lucide-react";
import {
  RESET_NOTIFICATION_SET,
  RESET_WEBSOSCKET_RESULT,
} from "../../../redux/actions/actionTypes";
import { MdOutlineClose } from "react-icons/md";
import { Tooltip } from "react-tooltip";

const NotifPanel = ({ panelType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [showNotif, setShowNotif] = useState(false);
  const notifRef = useRef(null);
  const className = panelType === "sup" ? "notif-panel-sup" : "notif-panel";

  const handleNotif = (e) => {
    e.preventDefault();
    setShowNotif(true);
    setNotRead(0);
    dispatch(getNotificationRequest({ offset: 0, limit: 10 }));
  };

  const unread = useSelector((state) => state.notifs?.response?.unreadCount);
  const hasMore = useSelector(
    (state) => state.notifs?.response?.pagination?.hasMore
  );

  // Debounce function to limit scroll event triggers
  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  const handleScroll = debounce((e) => {
    e.stopPropagation(); // Prevent scroll event from bubbling up

    if (notifRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = notifRef.current;
      // Add a slightly larger threshold to prevent edge cases
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        setOffset((prevOffset) => {
          const newOffset = prevOffset + 10;
          if (hasMore === true) {
            dispatch(getNotificationRequest({ offset: newOffset, limit: 10 }));
          }
          return newOffset;
        });
      }
    }
  }, 600); // Reduced debounce time for better responsiveness

  useEffect(() => {
    const currentRef = notifRef.current;

    if (showNotif && currentRef) {
      // Add wheel event listener to prevent scroll propagation
      const preventScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = currentRef;

        // Prevent scroll propagation only when at the boundaries
        if (
          (scrollTop <= 0 && e.deltaY < 0) || // At top and scrolling up
          (scrollTop + clientHeight >= scrollHeight && e.deltaY > 0) // At bottom and scrolling down
        ) {
          e.preventDefault();
        }
      };

      currentRef.addEventListener("wheel", preventScroll, { passive: false });
      hasMore && currentRef.addEventListener("scroll", handleScroll);

      return () => {
        currentRef.removeEventListener("wheel", preventScroll);
        currentRef.removeEventListener("scroll", handleScroll);
      };
    }
  }, [showNotif, hasMore]);

  const handleClickOutside = (event) => {
    if (notifRef.current && !notifRef.current.contains(event.target)) {
      setShowNotif(false);
      setOffset(0);
      dispatch({ type: RESET_WEBSOSCKET_RESULT });
      dispatch({ type: RESET_NOTIFICATION_SET });
    }
  };

  const closeNotif = () => {
    setOffset(0);
    setShowNotif(false);
    dispatch({ type: RESET_WEBSOSCKET_RESULT });
    dispatch({ type: RESET_NOTIFICATION_SET });
  };

  useEffect(() => {
    if (showNotif) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotif]);

  const handleNotifClick = (event, trackId) => {
    event.preventDefault();
    setOffset(0);
    panelType === "sup"
      ? navigate(`/TrackFeedback/${trackId}`)
      : navigate(`/TrackDetailsFinal/${trackId}`);
    closeNotif();
    dispatch({ type: RESET_NOTIFICATION_SET });
    dispatch({ type: RESET_WEBSOSCKET_RESULT });
  };

  const websocketNotifications =
    useSelector((state) => state.websocket?.result) || [];
  const apiNotifications =
    useSelector((state) => state.notifs?.notifs?.notifs) || [];
  const apiNotificationsLoading = useSelector(
    (state) => state.notifs?.notifs?.isLoading
  );

  const data = [
    ...websocketNotifications.map((notif) => ({
      ...notif.data,
      isWebSocket: true,
    })),
    ...apiNotifications.map((notif) => ({
      ...notif,
      isWebSocket: false,
    })),
  ];
  const [notRead, setNotRead] = useState(0);

  useEffect(() => {
    const newNotRead = websocketNotifications.length;
    if (newNotRead !== notRead) {
      setNotRead(newNotRead);
    }
  }, [websocketNotifications]);

  useEffect(() => {
    if (showNotif && notRead > 0) {
      dispatch(updateNotificationRequest());
    }
  }, [dispatch, showNotif, notRead]);

  return (
    <div className="notif-container">
      <button className="notif-bell" onClick={handleNotif}>
        <div className="unread-bell">
          {notRead > 0 && <div className="unread-bell-count">{notRead}</div>}
          <img src={readNotification} alt="Bell" />
        </div>
      </button>

      {showNotif && (
        <div ref={notifRef} className={className}>
          <div className="notif-header">
            <h3>Notifications</h3>
            <button className="close-button" onClick={closeNotif}>
              <MdOutlineClose size={20} />
            </button>
          </div>

          {apiNotificationsLoading && apiNotifications.length === 0 ? (
            <Card className="notif-loader-card">
              <Loader className="notifs-loader" />
            </Card>
          ) : data.length === 0 ? (
            <Card className="no-notif">
              <div className="no-notif-content">
                <BellOff />
                <p>Currently you don't have any notifications</p>
              </div>
            </Card>
          ) : (
            <>
              {data
                .slice(websocketNotifications.length, data.length) //slice for avoiding the repeating of notifs
                .map((notif, index) => (
                  <Card
                    className="notif-card"
                    key={notif?.notificationId || index}
                  >
                    <div className="notif-card-content">
                      <div
                        className={`notif-message ${
                          notif.isWebSocket ? "new-notification" : ""
                        }`}
                        onClick={(e) => handleNotifClick(e, notif?.trackId)}
                      >
                        <img src={notification} alt="Notification" />
                        <SMLLabel
                          key={notif._id}
                          value={(() => {
                            const trackName =
                              notif.message.match(/"([^"]+)"/)?.[1];

                            // Check if the track name is long, and add a tooltip if necessary
                            if (trackName && trackName.length > 40) {
                              const truncatedTrackName = `${trackName.substring(
                                0,
                                40
                              )}...`;

                              // Replace the track name in the original message with a span for the tooltip
                              const messageWithTooltip = notif.message.replace(
                                `"${trackName}"`,
                                `<span data-tip="${trackName}" data-for="notif-message-tooltip-${notif._id}">"${truncatedTrackName}"</span>`
                              );

                              return (
                                <>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: messageWithTooltip,
                                    }}
                                  />
                                  <Tooltip
                                    id={`notif-message-tooltip-${notif._id}`}
                                  />
                                </>
                              );
                            } else {
                              // Return the original message if the track name is short
                              return notif.message;
                            }
                          })()}
                          style="title2"
                          customStyle={{
                            overflowWrap: "anywhere",
                            fontWeight: notif.isWebSocket ? "600" : "normal", // Bold only for new notifications
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                ))}
              {apiNotificationsLoading && (
                <div className="loader-container">
                  <Loader className="notifs-loader" />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NotifPanel;
