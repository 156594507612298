import { useState, useEffect } from "react";
import "../../styles/Fonts.scss";
import "../../styles/HTMLControls.scss";
import "./SubmitTrackPopups.css";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import closeIcon from "../../assets/Images/closeIcon.svg";
import checkboxIcon from "../../assets/Images/checkboxIcon.svg";
import Backbutton from "../../components/Shared/BackButton/BackButton";
import successIcon from "../../assets/Images/SuccessCheckmark.svg";
import downloadIcon from "../../assets/Images/downloadIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadTrackRequest,
  postFeedbackRequest,
  putFeedbackMsRequest,
  putFeedbackRequest,
  putFinalFeedbackMsRequest,
} from "../../redux/actions";
import {
  RESET_ASSIGNED_SUPERVISOR,
  RESET_DOWNLOAD_TRACK,
  RESET_POST_FEEDBACK_ASSIGNED,
  RESET_PUT_FEEDBACK_ASSIGNED,
  RESET_PUT_FEEDBACK_MS_ASSIGNED,
  RESET_PUT_FINAL_FEEDBACK_MS_ASSIGNED,
} from "../../redux/actions/actionTypes";
import { Bars } from "react-loader-spinner";
import { MdOutlineQuestionMark } from "react-icons/md";

function SubmitTrackPopup(props) {
  const trackId = props.trackId;
  const isMaster = localStorage.getItem("isMaster");
  const [downloadTrack, setDownloadTrack] = useState(false);
  const [showDownloadTrack, setshowDownloadTrack] = useState(true);
  const [showSubmitEvaluation, setshowSubmitEvaluation] = useState(false);
  const [showFeedbackReviewed, setShowFeedbackReviewed] = useState(false);
  const [showFeedbackSubmitted, setShowFeedbackSubmitted] = useState(false);
  const [downloadLoading1, setDownloadLoading1] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const downloadLink = useSelector(
    (state) => state.downloadtrack?.downloadLink?.audio_signed_url
  );

  const handleSubmitEvaluation = () => {
    setDownloadTrack(true);
    const track_id = trackId;
    dispatch(downloadTrackRequest(track_id, "forDownload"));
  };

  useEffect(() => {
    const downloadFile = () => {
      if (downloadLink) {
        setDownloadLoading1(true);

        // Create a hidden anchor element
        const a = document.createElement("a");
        a.href = downloadLink;

        // Set download attribute to force download
        const fileName = downloadLink.split("/").pop();
        a.setAttribute("download", fileName);

        // Hide the element and add to the document
        a.style.display = "none";
        document.body.appendChild(a);

        // Programmatically click the link
        a.click();

        // Clean up
        setTimeout(() => {
          document.body.removeChild(a);
          setDownloadLoading1(false);
        }, 0);

        // Update UI state
        setshowSubmitEvaluation(true);
        setshowDownloadTrack(false);
      }
    };

    downloadFile();
  }, [downloadLink, downloadTrack]);

  const onShowFeedbackReceived = () => {
    setshowSubmitEvaluation(false);
    setShowFeedbackReviewed(true);
  };
  const postFeedbackAssigned = useSelector(
    (state) => state.postFeedback?.assigned
  );
  const putFeedbackAssigned = useSelector(
    (state) => state.putFeedback?.assigned
  );
  const putFeedbackMsAssigned = useSelector(
    (state) => state.putFeedbackMs?.assigned
  );
  const putFinalFeedbackAssigned = useSelector(
    (state) => state.putFinalFeedbackMs?.assigned
  );
  const location = useLocation();
  const status = location.state.status;

  useEffect(() => {
    if (isMaster === "true") {
      if (putFinalFeedbackAssigned) {
        setShowFeedbackReviewed(false);
        setShowFeedbackSubmitted(true);
      }
    } else {
      if (putFeedbackAssigned) {
        setShowFeedbackReviewed(false);
        setShowFeedbackSubmitted(true);
      }
    }
    // if (isMaster === "true" && status !== "in_review") {
    //   // Check all conditions if isMaster is "true"
    //   if (putFeedbackAssigned && putFinalFeedbackAssigned) {
    //     setShowFeedbackReviewed(false);
    //     setShowFeedbackSubmitted(true);
    //   }
    // } else {
    //   // Only check postFeedbackAssigned if isMaster is not "true"
    //   if (postFeedbackAssigned) {
    //     setShowFeedbackReviewed(false);
    //     setShowFeedbackSubmitted(true);
    //   }
    // }
  }, [
    postFeedbackAssigned,
    putFeedbackAssigned,
    putFeedbackMsAssigned,
    putFinalFeedbackAssigned,
    isMaster,
  ]);

  const handleNoDownload = () => {
    setshowDownloadTrack(false);
    setShowFeedbackReviewed(true);
  };

  const onNavigateDashboard = () => {
    dispatch({ type: RESET_POST_FEEDBACK_ASSIGNED });
    dispatch({ type: RESET_PUT_FEEDBACK_ASSIGNED });
    dispatch({ type: RESET_PUT_FEEDBACK_MS_ASSIGNED });
    dispatch({ type: RESET_PUT_FINAL_FEEDBACK_MS_ASSIGNED });
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    navigate("/supervisorDashboard");
  };

  const onBackBtn1 = () => {
    setshowSubmitEvaluation(false);
    setshowDownloadTrack(true);
    dispatch({ type: RESET_DOWNLOAD_TRACK });
  };

  const onBackBtn2 = () => {
    setShowFeedbackReviewed(false);
    setshowDownloadTrack(true);
  };
  const feedbackId = props.feedbackId;
  const ratings = props.ratings || [];
  const fieldNotes = props.fieldNotes;
  const syncPotential = props.syncPotential;
  const suggestedTargets = props.suggestedTargets;
  const notes = props.notes;
  const download_track = downloadTrack;
  const currentSupId = useSelector(
    (state) => state.sml.allArtistFetched?.artists?._id
  );
  const newId = useSelector((state) => state.postFeedback?.assigned?._id);
  const newTrackId = useSelector(
    (state) => state.getFeedback?.feedback[0]?._id
  );
  const handleSubmit = () => {
    if (isMaster === "false") {
      if (currentSupId === props.assignedSupId && feedbackId) {
        const newFeedbackData = {
          feedbackId: feedbackId,
          track_id: trackId,
          isfinal: true,
          supervisor_feedback: {
            ratings: ratings,
            fieldNotes: fieldNotes,
            sync_potential: syncPotential,
            suggested_targets: suggestedTargets,
            notes: notes,
            download_track: download_track,
          },
        };
        dispatch(putFeedbackRequest(newFeedbackData));
      } else {
        const newFeedbackData = {
          feedbackId: feedbackId,
          track_id: trackId,
          supervisor_feedback: {
            ratings: ratings,
            fieldNotes: fieldNotes,
            sync_potential: syncPotential,
            suggested_targets: suggestedTargets,
            notes: notes,
            download_track: download_track,
          },
        };
        dispatch(postFeedbackRequest(newFeedbackData));
      }
    } else {
      if (currentSupId !== props.assignedSupId) {
        const newFeedbackData = {
          feedbackId: feedbackId,
          track_id: trackId,
          isfinal: true,
          supervisor_feedback: {
            ratings: ratings,
            fieldNotes: fieldNotes,
            sync_potential: syncPotential,
            suggested_targets: suggestedTargets,
            notes: notes,
            download_track: download_track,
          },
        };
        dispatch(putFeedbackMsRequest(newFeedbackData));
      } else {
        if (feedbackId) {
          const newFeedbackData = {
            feedbackId: feedbackId,
            track_id: trackId,
            isfinal: true,
            supervisor_feedback: {
              ratings: ratings,
              fieldNotes: fieldNotes,
              sync_potential: syncPotential,
              suggested_targets: suggestedTargets,
              notes: notes,
              download_track: download_track,
            },
          };
          dispatch(putFeedbackMsRequest(newFeedbackData));
        } else {
          const newFeedbackData = {
            feedbackId: feedbackId,
            track_id: trackId,
            // isfinal: true,
            supervisor_feedback: {
              ratings: ratings,
              fieldNotes: fieldNotes,
              sync_potential: syncPotential,
              suggested_targets: suggestedTargets,
              notes: notes,
              download_track: download_track,
            },
          };
          dispatch(postFeedbackRequest(newFeedbackData));
        }
      }
    }
  };
  useEffect(() => {
    if (isMaster === "true" && currentSupId === props.assignedSupId && postFeedbackAssigned) {
      const newFeedbackData = {
        feedbackId: newId || feedbackId,
        track_id: trackId,
        isfinal: true,
        supervisor_feedback: {
          ratings: ratings,
          fieldNotes: fieldNotes,
          sync_potential: syncPotential,
          suggested_targets: suggestedTargets,
          notes: notes,
          download_track: download_track,
        },
      };
      dispatch(putFeedbackMsRequest(newFeedbackData));
    }
  }, [postFeedbackAssigned]);
  useEffect(() => {
    if (isMaster === "false") {
      if (currentSupId === props.assignedSupId && newId) {
        const newFeedbackData = {
          feedbackId: newId ? newId : feedbackId,
          track_id: trackId,
          isfinal: true,
          supervisor_feedback: {
            ratings: ratings,
            fieldNotes: fieldNotes,
            sync_potential: syncPotential,
            suggested_targets: suggestedTargets,
            notes: notes,
            download_track: download_track,
          },
        };
        dispatch(putFeedbackRequest(newFeedbackData));
      }
    }
  }, [postFeedbackAssigned]);
  useEffect(() => {
    if (isMaster === "true") {
      if ((newId || feedbackId) && putFeedbackMsAssigned) {
        const FinalFeedbackData = {
          track_id: newId ? newId : feedbackId,
          feedback_data: props.masterNotes,
        };
        dispatch(putFinalFeedbackMsRequest(FinalFeedbackData));
      }
    }
  }, [putFeedbackMsAssigned]);
  // if (currentSupId === props.assignedSupId) {
  //   const feedbackData = {
  //     track_id: trackId,
  //     supervisor_feedback: {
  //       ratings: ratings,
  //       fieldNotes: fieldNotes,
  //       sync_potential: syncPotential,
  //       suggested_targets: suggestedTargets,
  //       notes: notes,
  //       download_track: download_track,
  //     },
  //   };
  //   dispatch(postFeedbackRequest(feedbackData));
  // }
  // if (newTrackId && isMaster === "true" && status === "feedback_submitted") {
  //   const newFeedbackData = {
  //     track_id: newTrackId,
  //     supervisor_feedback: {
  //       ratings: ratings,
  //       fieldNotes: fieldNotes,
  //       sync_potential: syncPotential,
  //       suggested_targets: suggestedTargets,
  //       notes: notes,
  //       download_track: download_track,
  //     },
  //   };
  //   dispatch(putFeedbackMsRequest(newFeedbackData));
  // }

  // useEffect(() => {
  //   if (
  //     currentSupId === props.assignedSupId &&
  //     newId &&
  //     status === "in_review"
  //   ) {
  //     const FinalFeedbackData = {
  //       track_id: newId,
  //       feedback_data: props.masterNotes,
  //     };
  //     dispatch(putFinalFeedbackMsRequest(FinalFeedbackData));
  //   } else {
  //     const FinalFeedbackData = {
  //       track_id: newTrackId,
  //       feedback_data: props.masterNotes,
  //     };

  //     if (putFeedbackAssigned && isMaster === "true") {
  //       dispatch(putFinalFeedbackMsRequest(FinalFeedbackData));
  //     }
  //   }
  // }, [putFeedbackAssigned]);
  const handleClose = () => {
    if (showFeedbackSubmitted) {
      props.onClosePopup();
    } else {
      props.onClose();
    }
  };
  const downloadLoading = useSelector((state) => state.downloadtrack.isLoading);
  const postFeedbackLoading = useSelector(
    (state) => state.postFeedback?.isLoading
  );
  const putFeedbackMsLoading = useSelector(
    (state) => state.putFeedbackMs?.isLoading
  );
  const putFinalFeedbackMsLoading = useSelector(
    (state) => state.putFinalFeedbackMs?.isLoading
  );

  return (
    <div className="upload-container">
      <div className="upload-card-submittrack">
        <div className="downloadTrack-close-div" onClick={handleClose}>
          <img src={closeIcon}></img>
        </div>
        {downloadLoading1 ||
        downloadLoading ||
        postFeedbackLoading ||
        putFeedbackMsLoading ||
        putFinalFeedbackMsLoading ? (
          <div className="spinner-overlay">
            <Bars
              height="80"
              width="80"
              color="#23f0c7"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <div className="upload-main-submittrack">
              <div className="Popup-Content">
                {showDownloadTrack && (
                  <div className="downloadTrack-container">
                    <div className="downloadTrack-group">
                      <div
                        className="Mainheader"
                        style={{ textAlign: "center" }}
                      >
                        <SMLLabel value="Download Track?" style="Mainheader" />{" "}
                      </div>
                      <span className="subtitle3-submit-popup">
                        It looks like you rated this track pretty high. Would
                        you like to download it for potential placement
                        <span>
                          <MdOutlineQuestionMark />
                        </span>
                      </span>
                    </div>
                    <div
                      className="downloadTrack-btn-group "
                      role="group"
                      aria-label="First group"
                    >
                      <button
                        type="button"
                        className="btn-1 Button1 "
                        onClick={handleSubmitEvaluation}
                      >
                        <img
                          src={downloadIcon}
                          style={{ padding: "0px 10px 1px 2px" }}
                        />
                        Download Track
                      </button>
                      <button
                        type="button"
                        className="btn-2 Button1"
                        onClick={handleNoDownload}
                      >
                        No, dont download
                      </button>
                    </div>
                  </div>
                )}

                {showSubmitEvaluation && (
                  <div className="downloadTrack-container">
                    <div className="submitEvaluation-group">
                      <div
                        className="Mainheader"
                        style={{ textAlign: "center" }}
                      >
                        <SMLLabel value="Download Track?" style="Mainheader" />{" "}
                      </div>
                      <span className="subtitle3-submit-popup">
                        It looks like you rated this track pretty high. Would
                        you like to download it for potential placement
                        <span>
                          <MdOutlineQuestionMark />
                        </span>
                      </span>

                      <div>
                        <img
                          className="checkbox-image Button1"
                          src={checkboxIcon}
                        />
                        Track downloaded
                      </div>
                      <div
                        className="submitEvaluation-btn"
                        onClick={onShowFeedbackReceived}
                      >
                        <SMLButton
                          style="SMLButton1"
                          value="Continue Evaluation"
                        />
                      </div>
                    </div>

                    <div
                      className="submitEvaluation-backbtn"
                      onClick={onBackBtn1}
                    >
                      <Backbutton />{" "}
                    </div>
                  </div>
                )}

                {showFeedbackReviewed && (
                  <div className="feedbackReview-container">
                    <div className="downloadTrack-group">
                      <SMLLabel value="Submit Evaluation" style="Mainheader" />
                      <span className="subtitle3-submit-popup">
                        Your feedback notes and track evaluation will be
                        reviewed and modified before sending back to the artist.
                      </span>
                    </div>

                    <div
                      className="feedbackReview-backbtn"
                      onClick={onBackBtn2}
                    >
                      <Backbutton />{" "}
                    </div>
                    <div
                      className="feedback-btn-group"
                      role="group"
                      aria-label="First group"
                    >
                      <div className="feedback-submit-btn">
                        <SMLButton
                          style="SMLButton1"
                          value="Submit Evaluation"
                          handleClickEvent={handleSubmit}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn-2"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {showFeedbackSubmitted && (
                  <div className="feedbackSubmitted-container">
                    <div className="feedbackSubmitted-group">
                      <img
                        src={successIcon}
                        className="feedbackSubmitted-icon"
                      />
                      <span className="feedbackSubmitted-txt">
                        Your Feedback has been submitted
                      </span>
                      <div
                        className="feedbackSubmitted-btn"
                        onClick={onNavigateDashboard}
                      >
                        <SMLButton style="SMLButton1" value="Dashboard" />{" "}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SubmitTrackPopup;
