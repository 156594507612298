import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import "./DeleteAccountPopup.css";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteArtistProfileRequest } from "../../../redux/actions";

const DeleteAccountPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");


  const handleDeleteClick = () => {
    if (!reason) {
      // Show error that reason is required
      return;
    }

    dispatch(
      deleteArtistProfileRequest({
        confirmation: "DELETE", // Required by backend
        reason: reason, // Selected reason
        feedback: description, // Optional additional feedback
      })
    );
  };

  return (
    <Modal
      size="md"
      show={true}
      onHide={onClose}
      centered
      className="delete-account-modal"
    >
      <div className="modal-header">
        <div className="flex flex-col justify-content-start align-items-center">
          <h1 className="modal-title">Delete Account</h1>
          <p>
            This action cannot be undone. All your data will be permanently
            deleted.
          </p>
          <p className="subscription-warning">
            Any active subscription will be cancelled immediately and will not
            be refunded.
          </p>
        </div>
        <img
          src={closeIcon}
          alt="Close"
          onClick={onClose}
          className="close-icon"
        />
      </div>
      <div className="modal-body">
        <Form>
          <Form.Group>
            <Form.Label>
              What is your main reason for deleting your account?
            </Form.Label>
            <Form.Control
              as="select"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Select a reason"
            >
              <option value="">Select option</option>
              <option value="Created new account">Created new account</option>
              <option value="Privacy concerns">Privacy concerns</option>
              <option value="Not using the service">
                Not using the service
              </option>
              <option value="Found a better alternative">
                Found a better alternative
              </option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mt-5">
            <Form.Label>
              Please briefly describe what led to this decision?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="e.g. reasons for deleting account"
              maxLength="500"
            />
          </Form.Group>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {description.length}/500
          </div>

          <div className="modal-footer mt-5">
            <Button variant="secondary" onClick={onClose}>
              Go Back
            </Button>
            <Button variant="danger" onClick={handleDeleteClick}>
              Delete Account
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default DeleteAccountPopup;
