import {
  DELETE_PROFILE_FAILURE,
  DELETE_PROFILE_LOADING,
  DELETE_PROFILE_SUCCESS,
  RESET_DELETE_PROFILE,
} from "../actions/actionTypes";

export const deleteProfileReducer = (
  state = {
    ArtistDeleteReducer: {
      isError: false,
      isLoading: false,
      details: [],
      error: undefined,
    },
  },
  action
) => {
  switch (action.type) {
    case DELETE_PROFILE_LOADING:
      return {
        ...state,
        ArtistDeleteReducer: {
          isError: false,
          isLoading: true,
          details: [],
          error: undefined,
        },
      };
    case DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        ArtistDeleteReducer: {
          isError: false,
          isLoading: false,
          details: action.details,
          error: undefined,
        },
      };
    case DELETE_PROFILE_FAILURE:
      return {
        ...state,
        ArtistDeleteReducer: {
          isError: true,
          isLoading: false,
          details: [],
          error: action.error,
        },
      };
    case RESET_DELETE_PROFILE:
      return {
        ...state,
        ArtistDeleteReducer: {
          isError: false,
          isLoading: false,
          details: [],
          error: undefined,
        },
      };
    default:
      return state;
  }
};
