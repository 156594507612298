import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { Bars } from "react-loader-spinner";
import { getPbKeyRequest } from "../../../redux/actions";
import {
  getClientSecret,
  createSubReqeuest,
  subProAnalysisRequest,
} from "../../../redux/actions/paymentActions";
import { useParams } from "react-router-dom";

const appearance = {
  theme: "stripe",
  variables: {
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSizeBase: "16px",
    colorPrimary: "#4a90e2",
    colorBackground: "#f5f5f5",
    borderRadius: "8px",
    spacingUnit: "4px",
  },
};

const StripePaymentForm = ({
  selectedPlan,
  isPaymentTriggered,
  handlePayment,
  upgrade,
  serviceType,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const trackId = useParams();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { artists } = useSelector((state) => state.sml.allArtistFetched);
  const { artistSignedUp } = useSelector((state) => state.auth);

  const handleError = (error) => {
    setMessage(error.message || "An error occurred");
    setIsLoading(false);
    handlePayment();
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      setMessage("Stripe.js hasn't loaded yet. Please try again.");
      handlePayment();
      return;
    }
    setIsLoading(true);

    try {
      // 1. Submit form to validate
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }

      // 2. Create payment method directly
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          elements,
        });

      if (paymentMethodError) {
        handleError(paymentMethodError);
        return;
      }

      // 3. Create subscription with the payment method
      if (
        serviceType === "PAY_PER_TRACK" ||
        serviceType === "VIDEO_CONSULTATION"
      ) {
        dispatch(
          subProAnalysisRequest({
            trackId: trackId.trackId,
            priceId: selectedPlan?.monthlyId,
            paymentMethodId: paymentMethod.id,
            serviceType: serviceType,
          })
        );
      } else {
        dispatch(
          createSubReqeuest({
            id: upgrade ? artists?._id : artistSignedUp?.profile?._id,
            email: upgrade ? artists?.email : artistSignedUp?.profile?.email,
            priceId: selectedPlan?.id,
            methodId: paymentMethod.id,
          })
        );
      }
      setMessage("Payment successful!");
    } catch (err) {
      setMessage(err.message);
      handlePayment();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isPaymentTriggered && stripe && elements) {
      handleSubmit();
    }
  }, [isPaymentTriggered]);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setIsLoading(false);
      });
    }
  }, [elements]);

  return (
    <form id="payment-form">
      <PaymentElement />
      <AddressElement
        options={{
          mode: "billing",
          hide: ["addressLine2"],
        }}
      />
      {message && <div id="payment-message">{message}</div>}
      {isLoading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            visible={true}
          />
        </div>
      )}
    </form>
  );
};

const PaymentForm = (props) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const dispatch = useDispatch();
  const pbKey = useSelector(
    (state) => state.payment?.PbKeyReducer?.key?.publishableKey
  );
  const clientSecretResponse = useSelector(
    (state) => state.payment?.getClientSecretReducer?.secret
  );

  useEffect(() => {
    dispatch(getPbKeyRequest());
    dispatch(
      getClientSecret({
        mode: "subscription",
        amount: props.selectedPlan.amount,
        currency: "usd",
      })
    );
  }, []);

  useEffect(() => {
    if (clientSecretResponse) {
      setClientSecret(clientSecretResponse);
    }
  }, [clientSecretResponse]);

  useEffect(() => {
    if (pbKey) {
      const stripe = loadStripe(pbKey);
      setStripePromise(stripe);
    }
  }, [pbKey]);

  if (!stripePromise || !clientSecret) {
    return (
      <div className="spinner-overlay">
        <Bars
          height="80"
          width="80"
          color="#23f0c7"
          ariaLabel="bars-loading"
          visible={true}
        />
      </div>
    );
  }

  const options = {
    clientSecret,
    appearance,
    paymentMethodCreation: "manual",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripePaymentForm
        handlePayment={props.handlePayment}
        serviceType={props.serviceType}
        {...props}
      />
    </Elements>
  );
};

export default PaymentForm;
