
import React, { useCallback, useEffect, useState } from "react";
import Styles from "./TrackDetails.module.css";
import PlayIcon from "../../../assets/Icons/play.svg";
import PauseIcon from "../../../assets/Icons/pause.svg";
import SpeakerIcon from "../../../assets/Icons/speaker.svg";
import PreviousTrackIcon from "../../../assets/Icons/previous-track.svg";
import NextTrackIcon from "../../../assets/Icons/next-track.svg";
import { formatTime } from "../../../pages/Reviews/TrackUnderReview";
import { Tooltip } from "react-tooltip";
const TrackPlayer = ({
  track,
  isPlaying,
  wavesurfer,
  done,
  commentEnabled,
}) => {
  const [volume, setVolumne] = useState(0.75);

  const handleVolumneChange = (event) => {
    setVolumne(parseFloat(event.target.value));
  };

  const onPlayPause = useCallback(() => {
    if (done === true && wavesurfer) {
      wavesurfer.playPause();
    }
  }, [wavesurfer, done]);

  const onSpacePlayPause = () => {
    if (done === true && wavesurfer) {
      wavesurfer.playPause();
    }
  };

  const onVolumeChange = useCallback(
    (value) => {
      wavesurfer && wavesurfer.setVolume(value);
    },
    [wavesurfer]
  );

  useEffect(() => {
    onVolumeChange(volume);
  }, [volume, onVolumeChange]);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.code === "Space" && done === true && commentEnabled === false) {
        event.preventDefault();
        onSpacePlayPause();
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [onPlayPause, wavesurfer, done, commentEnabled]);

  const onReload = useCallback(() => {
    if (wavesurfer) {
      wavesurfer.stop();
      wavesurfer.on("error", (e) => {
        console.error("Error loading track:", e);
      });
    }
  }, [wavesurfer, track.url, volume]);

  return (
    <div
      className={Styles.trackPlayer}
      tabIndex={0}
      onFocus={() => console.log("Player focused")}
    >
      <div className={Styles.trackInfo}>
        <h3
          data-tooltip-id="trackTitle-player"
          data-tooltip-content={track?.title}
          className={Styles.trackTitle}
        >
          {track?.title}
        </h3>
        <Tooltip id="trackTitle-player" multiline="true" place="top" />
      </div>

      <div className={Styles.controlsContainer}>
        <div className={Styles.durationContainer}>
          <p style={{ marginBottom: "0" }}>
            {wavesurfer ? formatTime(wavesurfer.getDuration()) : "0:00"}
          </p>
        </div>
        <div className={Styles.trackControls}>
          <button onClick={onReload}>
            <img
              src={PreviousTrackIcon}
              alt="previous track"
              height="24px"
              width="24px"
            />
          </button>
          <button onClick={onPlayPause}>
            <img
              src={isPlaying ? PauseIcon : PlayIcon}
              alt="play"
              height="40px"
              width="40px"
            />
          </button>
          <button>
            <img
              src={NextTrackIcon}
              alt="next track"
              height="24px"
              width="24px"
            />
          </button>
        </div>

        <div className={Styles.volumeControls}>
          <img src={SpeakerIcon} alt="speaker" height="16px" width="16px" />
          <div className={Styles.rangeContainer}>
            <input
              type="range"
              id="customRangeInput"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumneChange}
              className={Styles.customRange}
              style={{
                background: `linear-gradient(to right, #FF4405 0%, #FF4405 ${
                  volume * 100
                }%, #57130A ${volume * 100}%, #57130A 100%)`,
              }}
            />
          </div>
        </div>
      </div>
      <div style={{width:"25%"}}></div>
    </div>
  );
};

export default TrackPlayer;
