import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import { AlertTriangleIcon } from "lucide-react";
import "./RestrictionPopup.css";
import plans from "../../../pages/SignUpPage/ChoosePlan/ChoosePlan.json";
import checkBox from "../../../assets/Images/checkboxIcon.svg";

const VidFeedbackRestrictionPopup = ({ handleClick, onClose }) => {
  return (
    <Modal
      show={true}
      onHide={onClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="restriction-sub-modal"
    >
      <div className="restriction-sub-modal-content-wrapper">
        <div
          className="restriction-sub-modal-close-btn"
          onClick={() => {
            // setShowChoosePlan(true);
            onClose();
          }}
        >
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="restriction-sub-modal-body-wrapper">
          <AlertTriangleIcon className="restriction-sub-modal-icon" />
          {/* <div className="restriction-sub-modal-title">Upgrade Required</div> */}
          <div className="restriction-sub-modal-message" style={{fontWeight : "600", fontSize :  "1.1rem"}}>
          
          Are you sure you want to give Video Feedback? 
          
          </div>
          <div className="restriction-sub-modal-message">
            <p style={{ fontWeight: "600", marginBottom: "2rem" }}>
                as this track comes under <span style={{fontWeight  : "700", fontSize : "1.1rem"}}> BASIC </span>plan
              
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-4">
            <div
              className="restriction-sub-action-button primary"
              onClick={() => {
                handleClick(true);
                onClose();
              }}
            >
              YES
            </div>
            <div
              className="restriction-sub-action-button1 secondary"
              onClick={() => {
                onClose();
              }}
            >
              NO
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VidFeedbackRestrictionPopup;
