// actions.js
import {
  DELETE_TRACK_FAILURE,
  DELETE_TRACK_REQUEST,
  DELETE_TRACK_SUCCESS,
  GET_SINGLE_TRACK_REQUEST,
  GET_SINGLE_TRACK_SUCCESS,
  GET_SINGLE_TRACK_FAILURE,
  GET_TRACK_FAILURE,
  GET_TRACK_REQUEST,
  GET_TRACK_SUCCESS,
  POST_TRACK_FAILURE,
  POST_TRACK_REQUEST,
  POST_TRACK_SUCCESS,
  UPDATE_TRACK_FAILURE,
  UPDATE_TRACK_REQUEST,
  UPDATE_TRACK_SUCCESS,
  RESET_UPLOAD_TRACK,
  GET_ALL_TRACK_FETCH,
  GET_ALL_TRACK_FAILURE,
  GET_ALL_TRACK_SUCCESS,
} from "./actionTypes";

export const resetUploadTrack = () => ({
  type: RESET_UPLOAD_TRACK,
});

export const getTrackRequest = (trackId) => ({
  type: GET_TRACK_REQUEST,
  payload: trackId,
});

export const getTrackSuccess = (track) => ({
  type: GET_TRACK_SUCCESS,
  payload: track,
});

export const getTrackFailure = (error) => ({
  type: GET_TRACK_FAILURE,
  payload: error,
});

export const postTrackRequest = (payload) => ({
  type: POST_TRACK_REQUEST,
  payload,
});

export const postTrackSuccess = (track) => ({
  type: POST_TRACK_SUCCESS,
  payload: track,
});

export const postTrackFailure = (error) => ({
  type: POST_TRACK_FAILURE,
  payload: error,
});

export const getSingleTrackRequest = (trackId) => ({
  type: GET_SINGLE_TRACK_REQUEST,
  payload: trackId,
});

export const getAllTrackSuccess = (trackStatus) => ({
  type: GET_ALL_TRACK_SUCCESS,
  payload: trackStatus,
});
export const getAllTrackFailure = (error) => ({
  type: GET_ALL_TRACK_FAILURE,
  payload: error,
});

export const getSingleTrackSuccess = (track) => ({
  type: GET_SINGLE_TRACK_SUCCESS,
  payload: track,
});

export const getSingleTrackFailure = (error) => ({
  type: GET_SINGLE_TRACK_FAILURE,
  payload: error,
});

export const updateTrackRequest = (track) => ({
  type: UPDATE_TRACK_REQUEST,
  payload: track,
});

export const updateTrackSuccess = (track) => ({
  type: UPDATE_TRACK_SUCCESS,
  payload: track,
});

export const updateTrackFailure = (error) => ({
  type: UPDATE_TRACK_FAILURE,
  payload: error,
});

export const deleteTrackRequest = (trackId) => ({
  type: DELETE_TRACK_REQUEST,
  payload: trackId,
});

export const deleteTrackSuccess = (trackId) => ({
  type: DELETE_TRACK_SUCCESS,
  payload: trackId,
});

export const deleteTrackFailure = (error) => ({
  type: DELETE_TRACK_FAILURE,
  payload: error,
});
