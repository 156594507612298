import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;

export const getProAnalysisApi = async (data) => {
  try {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }

    const url = `${BASE_URL}/billing/payments?trackId=${data.trackId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    
    const response = await api.get(url, config);
    return response.data;
  } catch (error) {
    console.error("error in getting subscription by profile", error.message);
    throw error;
  }
};
