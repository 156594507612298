import {
  GET_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_RESPONSE_SUCCESS,
  GET_NOTIFICATION_SUCCESS,
  RESET_NOTIFICATION_SET,
} from "../actions/actionTypes";

const initialState = {
  notifs: {
    notifs: [],
    isLoading: false,
    isError: false,
    error: undefined,
  },
  response: {
    pagination: {},
    unreadCount: 0,
    isLoading: false,
    isError: false,
    error: undefined,
  },
};

export const getNotificationReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        notifs: {
          ...state.notifs, // Preserve other properties
          isLoading: true,
          isError: false,
          error: null,
        },
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifs: {
          ...state.notifs, // Preserve other properties
          isLoading: false,
          isError: false,
          error: undefined,
          notifs: [...state.notifs.notifs, ...action.payload?.notifs?.notifs], // Append new notifications
        },
      };
    case GET_NOTIFICATION_RESPONSE_SUCCESS:
      return {
        ...state,
        response: {
          isLoading: false,
          isError: false,
          error: undefined,
          pagination: action.payload?.response?.response?.pagination,
          unreadCount: action.payload?.response?.response?.unreadCount,
        },
      };
    case GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        notifs: {
          ...state.notifs, // Preserve other properties
          isLoading: false,
          isError: true,
          error: action.payload,
        },
      };
    case RESET_NOTIFICATION_SET:
      return initialState;
    default:
      return state;
  }
};
