import React, { useEffect, useState } from "react";
import RegisterComponent from "./Register/Register";
import ContactInfoComponent from "./ContactInfo/ContactInfo";
import SuccessPageComponent from "./SuccessPage/SuccessPage";
import { useDispatch, useSelector } from "react-redux";
import { supSignupRequest } from "../redux/actions";
import { supSocialSignupRequest } from "../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

function SupervisorPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLogin, setShowLogin] = useState(false);
  const [currentStep, setCurrentStep] = useState("register");
  const [contactData, setContactData] = useState({
    company: "",
    profession: "",
    payment_info: {
      venmo: "",
      zelle: "",
    },
  });
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    createPassword: "",
    verifyPassword: "",
    agreeConditions: "",
    profileType: "supervisor",
  });
  const { supSignedUp, artistSignedUp, artistSocialSignedIn } = useSelector(
    (state) => state.auth
  );
  const goToLogin = () => {
    setShowLogin(true);
  };
  const handleRegisterSubmit = (registerData) => {
    setFormData(registerData);
    setCurrentStep("contacts");
  };
  useEffect(() => {
    if (artistSignedUp?.is_new_user === false) {
      navigate("/supervisorDashboard");
    }
  }, [artistSignedUp]);
  useEffect(() => {
    if (artistSocialSignedIn || supSignedUp || artistSignedUp?.is_new_user) {
      setCurrentStep("contact");
    }
  }, [artistSocialSignedIn, supSignedUp, artistSignedUp]);
  const handleContactInfoSubmit = (contactData) => {
    if (formData.credential) {
      dispatch(
        supSocialSignupRequest(
          formData.credential,
          formData.provider,
          formData.profileType,
          contactData
        )
      );
    } else {
      const completeData = {
        ...formData,
        ...contactData,
        profileType: "supervisor",
      };
      dispatch(supSignupRequest(completeData));
    }
  };
  const onClose = () => {
    navigate("/");
  };

  const handleBackClick = (contactData) => {
    setContactData(contactData);
    setCurrentStep("register");
  };

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return showLogin ? (
    navigate("/supervisor/login")
  ) : (
    <Modal
      show={true}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal"
    >
      {currentStep === "register" && (
        <RegisterComponent
          onRegisterClick={handleRegisterSubmit}
          onClose={onClose}
          formData={formData}
          goToLogin={goToLogin}
        />
      )}
      {currentStep === "contacts" && (
        <ContactInfoComponent
          contactData={contactData}
          onContactInformationClick={handleContactInfoSubmit}
          onBackClick={handleBackClick}
          onClose={onClose}
        />
      )}
      {currentStep === "contact" && <SuccessPageComponent onClose={onClose} />}
    </Modal>
  );
}

export default SupervisorPage;
