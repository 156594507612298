import { jwtDecode } from "jwt-decode";
import api from "../api";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getNotifsApi = async ({ offset, limit }) => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No access token found");
      return;
    }
    // const decodedToken = jwtDecode(accessToken);
    // const artistId = decodedToken["custom:artist_id"];
    const url = `${BASE_URL}/notifications?offset=${offset}&limit=${limit}`;
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await api.get(url, config);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching tracks:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const readNotifApi = async () => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No access token found");
      return;
    }
    const url = `${BASE_URL}/notifications/read`;
    let config = {
      method: "put",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.request(url, config);
    console.log(response, response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching tracks:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
