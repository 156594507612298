import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_SUPERVISOR_FAILURE,
  GET_SUPERVISOR_LOADING,
  GET_SUPERVISOR_REQUEST,
  GET_SUPERVISOR_SUCCESS,
} from "../actions/actionTypes";
import {
  getALLSupervisorFailure,
  getAllSupervisorRequest,
  getAllSupervisorSuccess,
} from "../actions/supervisorActions";
import { getSupervisorApi } from "../../api/supervisor/getSupervisor";

function* getSupervisor(action) {
  try {
    yield put({ type: GET_SUPERVISOR_LOADING });
    const supervisors = yield call(getSupervisorApi);
    yield put({ type: GET_SUPERVISOR_SUCCESS, supervisors: supervisors });
  } catch (error) {
    yield put({ type: GET_SUPERVISOR_FAILURE }, error);
  }
}

function* supervisorSaga() {
  yield takeLatest(GET_SUPERVISOR_REQUEST, getSupervisor);
}
export default supervisorSaga;
