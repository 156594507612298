import api from "../api";

const BASE_URL = process.env.REACT_APP_API_URL; // Use the proxy base URL

export const deleteArtistProfileApi = async (data) => {
  try {
    const accessToken = localStorage.getItem("token");
    const artistId = localStorage.getItem("artistId");

    const response = await api.request({
      url: `${BASE_URL}/profiles/close`,
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        confirmation: "DELETE",
        reason: data.reason
      }
    });
    return response?.data;
  } catch (error) {
    console.error("error deleting artist profile", error);
    throw error;
  }
};
