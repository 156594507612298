import React, { useEffect, useState } from "react";
import AboutYouComponent from "./AboutYou/AboutYou";
import ContactInformationComponent from "./ContactInformation/ContactInformation";
import WelcomeComponent from "./Welcome/Welcome";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChoosePlan from "./ChoosePlan/ChoosePlan";
import PaymentPopup from "../../components/common/payment-popup/PaymentPopup";
import "./SignUpPage.css";
import Login from "../LoginPage/LoginPage";
import {
  RESET_CREATE_SUB,
  RESET_GET_CLIENT,
  RESET_PB_KEY,
} from "../../redux/actions/actionTypes";
function SignUpPage({
  onClose,
  selectedPlan,
  planIndex,
  fromHome,
  handleIndex,
  isYearly,
}) {
  const dispatch = useDispatch();
  const [currentComponent, setCurrentComponent] = useState("plan");
  const [contactInfo, setContactInfo] = useState({
    fullName: "",
    email: "",
    createPassword: "",
    verifyPassword: "",
    agreeConditions: "",
    profileType: "artist",
  });
  const [roleOptions, setRoleOptions] = useState([]);
  const [experienceWithSync, setExperienceWithSync] = useState(false);
  const [smlLeadInfo, setSmlLeadInfo] = useState("");
  const [personalLinks, setPersonalLinks] = useState([]);
  const [musicExperienceInfo, setMusicExperienceInfo] = useState("");
  const [guidanceNeededInfo, setGuidanceNeededInfo] = useState("");
  const [selectedPlanData, setSelectedPlanData] = useState(null); // State to store selected plan
  const [aboutYouData, setAboutYouData] = useState(null);
  const [index, setIndex] = useState();
  const [isYearlyPlan, setIsYearlyPlan] = useState(null);
  const [sendIndex, setSendIndex] = useState(null);
  const [isFromHome, setIsFromHome] = useState(fromHome);
  const [selectedName, setSelectedName] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [membPlan, setMembPlan] = useState("");
  const [signupMethod, setSignupMethod] = useState('email');

  const handleRoleOptions = (roles) => {
    setRoleOptions(roles);
  };
  const handleExperienceWithSync = () => {
    setExperienceWithSync(!experienceWithSync);
  };
  const handleSmlLeadInfo = (data) => {
    setSmlLeadInfo(data);
  };
  const handlePersonalLinks = (links) => {
    setPersonalLinks(links);
  };
  const handleMusicExp = (data) => {
    setMusicExperienceInfo(data);
  };
  const handleGuidanceInfo = (data) => {
    setGuidanceNeededInfo(data);
  };
  useEffect(() => {}, [personalLinks]);
  const handleContactInformationClick = (data) => {
    setContactInfo(data);
    if (data.provider) {
      setSignupMethod('social');
    } else {
      setSignupMethod('email');
    }
    setCurrentComponent("about");
  };

  const handleAboutYouClick = (userData) => {
    console.log('handleAboutYouClick - userData:', userData);
    console.log('handleAboutYouClick - selectedPlan prop:', selectedPlan);
    console.log('handleAboutYouClick - selectedPlanData:', selectedPlanData);
    
    setAboutYouData(userData);
    
    // Check both selectedPlan (from props) and selectedPlanData (from state)
    const currentPlan = selectedPlan || selectedPlanData;
    
    // If no plan selected (from Navbar) or it's explicitly a free plan
    if (!currentPlan || currentPlan.name?.toLowerCase() === 'free' || currentPlan.skipPayment) {
      console.log('No plan or Free plan detected, going to welcome');
      setCurrentComponent("welcome");
    } else {
      // For any paid plan (Basic, Pro, etc)
      console.log('Paid plan detected, going to payment');
      dispatch({ type: RESET_PB_KEY });
      dispatch({ type: RESET_GET_CLIENT });
      dispatch({ type: RESET_CREATE_SUB });
      setCurrentComponent("payment");
    }
  };

  const onBackClick = () => {
    setCurrentComponent("contact");
  };
  const onContactBackClick = (data) => {
    setIsFromHome(false);
    setContactInfo(data);
    setCurrentComponent("plan");
  };
  const onPaymentBackClick = () => {
    setCurrentComponent("about");
  };
  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevent click event from bubbling up to parent
  };
  const onNextClick = () => {
    setCurrentComponent("contact");
  };
  const onNextClick1 = (plan, index, isYearly) => {
    console.log('onNextClick1 - plan:', plan);
    
    if (plan) {
      // Set the plan data
      setSelectedPlanData(plan);
      setIndex(index);
      setIsYearlyPlan(isYearly);
      
      // Set membership type based on plan name
      const planName = plan.name?.toLowerCase();
      if (planName === 'free') {
        setMembPlan("free");
      } else if (planName === 'basic') {
        setMembPlan("basic");
      } else if (planName === 'pro') {
        setMembPlan("pro");
      } else if (planName?.includes('sync_partner')) {
        setMembPlan("sync_partner");
      }
    }
    
    // Always proceed to contact
    setCurrentComponent("contact");
  };
  const onNextClick2 = () => {
    setCurrentComponent("welcome");
  };
  useEffect(() => {
    if (isFromHome) {
      setCurrentComponent("contact");
    }
  }, [isFromHome]);
  useEffect(() => {
    if (isFromHome) {
      setSendIndex(planIndex);
      setIsYearlyPlan(isYearly);
    } else {
      setSendIndex(index);
      if (fromHome) {
        handleIndex(index, isYearlyPlan);
      }
    }
  }, [index, setIndex, planIndex, isYearlyPlan, setIsYearlyPlan]);
  const goToLogin = () => {
    setShowLogin(true);
  };
  useEffect(() => {
    if (fromHome && selectedPlan) {
      console.log('Setting selected plan from ParallaxPlan:', selectedPlan);
      setSelectedPlanData(selectedPlan);
      setMembPlan(selectedPlan.name?.toLowerCase());
    }
  }, [fromHome, selectedPlan]);
  return showLogin ? (
    <Login onClose={onClose} />
  ) : (
    <Modal
      show={true}
      onHide={onClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal"
    >
      <div className="signUp-container">
        <div className="signup-card">
          <div onClick={handleContainerClick}>
            {currentComponent === "plan" && (
              <ChoosePlan
                onNextClick1={onNextClick1}
                isYearly={isYearlyPlan}
                closePopup={onClose}
                planIndex={sendIndex}
              />
            )}
            {currentComponent === "contact" && (
              <ContactInformationComponent
                membPlan = {membPlan}
              contactData={contactInfo}
                onContactInformationClick={handleContactInformationClick}
                closePopup={onClose}
                handleBack={onContactBackClick}
                goToLogin={goToLogin}
              />
            )}
            {currentComponent === "about" && (
              <AboutYouComponent
                roleOptions={roleOptions}
                handleRoleOptions={handleRoleOptions}
                contactInfo={contactInfo}
                onAboutClick={handleAboutYouClick}
                onBackClick={onBackClick}
                closePopup={onClose}
                experienceWithSync={experienceWithSync}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                handleExperienceWithSync={handleExperienceWithSync}
                smlLeadInfo={smlLeadInfo}
                handleSmlLeadInfo={handleSmlLeadInfo}
                personalLinks={personalLinks}
                handlePersonalLinks={handlePersonalLinks}
                musicExperienceInfo={musicExperienceInfo}
                handleMusicExp={handleMusicExp}
                guidanceNeededInfo={guidanceNeededInfo}
                handleGuidanceInfo={handleGuidanceInfo}
                membPlan={membPlan}
              />
            )}
            {currentComponent === "payment" && selectedPlanData && (
              <PaymentPopup
                selectedPlan={
                  selectedPlanData.name?.toLowerCase() === 'free' 
                    ? { ...selectedPlanData, amount: 0 } 
                    : (selectedPlan || selectedPlanData)
                }
                contactInfo={contactInfo}
                aboutYouData={aboutYouData}
                nextClick={onNextClick2}
                closePopup={onClose}
                backClick={onPaymentBackClick}
                isYearly={isYearlyPlan}
              />
            )}
            {currentComponent === "welcome" && (
              console.log('Rendering Welcome component'),
              <WelcomeComponent
              membPlan = {membPlan}
                signupMethod={signupMethod}
                onBackClick={onBackClick}
                closePopup={onClose}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SignUpPage;
