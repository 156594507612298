import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import SMLButton from "../../Shared/SMLButton/SMLButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutRequest } from "../../../redux/actions/authActions";
import {
  RESET_ASSIGNED_SUPERVISOR,
  RESET_CONFIRM_MAIL,
  RESET_DELETE_PROFILE,
  RESET_GET_ALL_TRACK,
  RESET_GET_SINGLE_TRACK,
} from "../../../redux/actions/actionTypes";
import { RESET_ARTIST } from "../../../redux/actions/smlActions";
import { googleLogout } from "@react-oauth/google";
import { logoutFromWebSocket } from "../../../api/websocket/websocketApi";

const ClosedAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(logoutRequest());
    dispatch({ type: RESET_GET_SINGLE_TRACK });
    dispatch({ type: RESET_GET_ALL_TRACK });
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    dispatch({ type: RESET_ARTIST });
    dispatch({ type: RESET_CONFIRM_MAIL });
    dispatch({ type: RESET_DELETE_PROFILE });
    googleLogout();
    logoutFromWebSocket();
  }, []);
  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="forgot-pass-modal"
    >
      <div className="confirm-mail-container">
        <h2>Your account has been successfully closed!</h2>

        {/* {!confirmed && ( */}
        <div className="flex flex-column justify-content-center align-items-center gap-2 mt-4 mb-4">
          <p style={{ textAlign: "center" }}>
            Thank you for the time you spent with us
          </p>
          <p style={{ textAlign: "center" }}>
            If you'd like to return in the future, you can create a new account
            at any time.
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            We wish you all the best!
          </h5>
        </div>
        <div
          className="d-flex justify-content-center"
          onClick={() => {
            navigate("/");
          }}
        >
          <SMLButton value="Home" style="SMLButton2" />
        </div>
        {/* )} */}

        {/* {isError && (
      <div>
        {error === "User is already confirmed." ? (
          <p className="success-message">{error}</p>
        ) : (
          <p className="error-message">{error}</p>
        )}
        <div
          className="d-flex justify-content-center"
          onClick={handleHomeClick}
        >
          <SMLButton value="Home" style="SMLButton2" />
        </div>
      </div>
    )} */}
      </div>
    </Modal>
  );
};

export default ClosedAccount;
