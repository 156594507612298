import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_UPLOAD_STATS_FAILURE,
  GET_UPLOAD_STATS_LOADING,
  GET_UPLOAD_STATS_REQUEST,
  GET_UPLOAD_STATS_SUCCESS,
} from "../actions/actionTypes";
import { uploadStatsApi } from "../../api/track/uploadStatsApi";

function* uploadStats(action) {
  yield put({ type: GET_UPLOAD_STATS_LOADING });
  try {
    const type = action.payload;
    const stats = yield call(uploadStatsApi, type);
    yield put({ type: GET_UPLOAD_STATS_SUCCESS, stats: stats });
  } catch (error) {
    yield put({ type: GET_UPLOAD_STATS_FAILURE, payload: error.message });
  }
}

export default function* uploadStatsSaga() {
  yield all([takeLatest(GET_UPLOAD_STATS_REQUEST, uploadStats)]);
}
