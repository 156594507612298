import React, { useState } from "react";
import DefaulTrackImage from "../../../assets/Images/TrackDefaultImg.svg";
import SMLButton from "../../common/styled-components/SMLButton";
import Styles from "./TrackDetails.module.css";
import NotSubmittedIcon from "../../../assets/Icons/not-submitted.svg";
import SMLTextWithLabel from "../styled-components/SMLTextWithLabel";
import ViewSubmission from "../../UI/viewSubmission/viewSubmission";
import FeedbackForum from "../../../pages/Feedback/FeedbackForum";
import { ArrowUpRight } from 'lucide-react';
import { useNavigate } from "react-router-dom";

export default function TrackDetailsHeader({ track }) {
  const [showPopup, setShowPopup] = useState(false);
  const [showViewSubmissionPopup, setShowViewSubmissionPopup] = useState(false);
  const navigate = useNavigate();

  const handleViewSubmitFeedback = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleViewSubmission = () => {
    setShowViewSubmissionPopup(true);
  };

  const handleViewCloseSubmission = () => {
    setShowViewSubmissionPopup(false);
  };

  const handleOriginalClick = (e) => {
    e.stopPropagation();
    navigate(`/TrackDetails/${track?.original_track_id}`, {
      state: { status: 'submitted' },
    });
  };

  return (
    <div className={Styles.trackHeaderContainer}>
      <div>
        <img
          src={DefaulTrackImage}
          alt="Track"
          height={"100%"}
          width={"100%"}
        />
      </div>
      <div>
        <h2>Track Name</h2>
        {track?.original_track_id && (
          <div className={Styles.revisionInfo}>
            <SMLTextWithLabel 
              label="Revision"
              text={
                <div className={Styles.revisionContent}>
                  <span>{track.revision_number || "1"}</span>
                  <button 
                    onClick={handleOriginalClick} 
                    className={Styles.viewOriginalButton}
                  >
                    <ArrowUpRight size={14} />
                  </button>
                </div>
              }
            />
          </div>
        )}
        <h4 onClick={handleViewSubmission} style={{ cursor: "pointer" }}>
          My Submission
        </h4>
        {showViewSubmissionPopup && (
          <div className={Styles.popup}>
            <ViewSubmission onClose={handleViewCloseSubmission} />
          </div>
        )}
      </div>
      <div>
        <SMLTextWithLabel text={"Not Submitted"} textImg={NotSubmittedIcon} />
        <SMLButton onClick={handleViewSubmitFeedback}>
          Submit for Feedback
        </SMLButton>
        {showPopup && (
          <div className={Styles.popup}>
            <FeedbackForum onClose={handleClosePopup} />
          </div>
        )}
      </div>
    </div>
  );
}