import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Bars } from "react-loader-spinner";
import {
  createSubReqeuest,
  getSubDetailsRequest,
  subProAnalysisRequest,
} from "../../../redux/actions/paymentActions";
import PaymentForm from "./PaymentForm";
import { useParams } from "react-router-dom";

const CheckoutForm = ({ selectedPlan, ...props }) => {
  const [existingSubscription, setExistingSubscription] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [showNewPaymentMethod, setShowNewPaymentMethod] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const dispatch = useDispatch();
  const trackId = useParams();
  const subByProfile = useSelector(
    (state) => state.payment?.getSubByProfileReducer?.data
  );
  const subDetails = useSelector(
    (state) => state.payment?.getSubDetailsReducer?.data
  );
  const { artists } = useSelector((state) => state.sml.allArtistFetched);
  const { artistSignedUp } = useSelector((state) => state.auth);

  useEffect(() => {
    if (subByProfile && subByProfile.length > 0) {
      const subscription = subByProfile[0];
      setExistingSubscription(subscription);

      if (subscription.stripeSubscriptionId) {
        dispatch(
          getSubDetailsRequest({
            subscriptionId: subscription.stripeSubscriptionId,
          })
        );
      }
    }
  }, [subByProfile]);

  useEffect(() => {
    if (subDetails && Array.isArray(subDetails) && subDetails.length > 0) {
      setPaymentMethods(subDetails);
    }
  }, [subDetails]);

  const handlePaymentMethodSelect = (methodId) => {
    setSelectedPaymentMethod(methodId);
  };

  useEffect(() => {
    if (
      props.isPaymentTriggered &&
      paymentMethods.length > 0 &&
      !showNewPaymentMethod
    ) {
      if (
        props.serviceType === "PAY_PER_TRACK" ||
        props.serviceType === "VIDEO_CONSULTATION"
      ) {
        dispatch(
          subProAnalysisRequest({
            trackId: trackId.trackId,
            priceId: selectedPlan?.monthlyId,
            paymentMethodId: selectedPaymentMethod,
            serviceType: props.serviceType,
          })
        );
      } else {
        dispatch(
          createSubReqeuest({
            id: props.upgrade ? artists?._id : artistSignedUp?.profile?._id,
            email: props.upgrade
              ? artists?.email
              : artistSignedUp?.profile?.email,
            priceId: selectedPlan?.id,
            methodId: selectedPaymentMethod,
          })
        );
      }
      props.handlePayment();
    }
  }, [props.isPaymentTriggered]);

  useEffect(() => {
    console.log(showNewPaymentMethod, "show");
  }, [showNewPaymentMethod]);

  return (
    <div>
      {
        isLoadingProfile || isLoadingDetails ? (
          <div className="spinner-overlay">
            <Bars
              height="80"
              width="80"
              color="#23f0c7"
              ariaLabel="bars-loading"
              visible={true}
            />
          </div>
        ) : paymentMethods.length > 0 && !showNewPaymentMethod ? (
          <div className="payment-methods-section">
            <div className="saved-payment-methods">
              {paymentMethods.map((method) => (
                <div
                  key={method.id}
                  className={`saved-method ${
                    selectedPaymentMethod === method.id ? "selected" : ""
                  }`}
                  onClick={() => handlePaymentMethodSelect(method.id)}
                >
                  <span>
                    {method.brand.toUpperCase()} •••• {method.last4}
                  </span>
                  <span>
                    Expires {method.expMonth}/{method.expYear}
                  </span>
                </div>
              ))}
              <button
                type="button"
                className="change-payment-btn"
                onClick={() => setShowNewPaymentMethod(true)}
              >
                Use a different payment method
              </button>
            </div>
          </div>
        ) : (
          // ) : showNewPaymentMethod ? (
          <PaymentForm
            selectedPlan={selectedPlan}
            handlePayment={props.handlePayment}
            serviceType={props.serviceType}
            {...props}
          />
        )
        // ) : (
        //   <div className="spinner-overlay">
        //     <Bars
        //       height="80"
        //       width="80"
        //       color="#23f0c7"
        //       ariaLabel="bars-loading"
        //       visible={true}
        //     />
        //   </div>
        // )}
      }
    </div>
  );
};

export default CheckoutForm;
