import {
  GET_ORIGINAL_TRACK_FAILURE,
  GET_ORIGINAL_TRACK_LOADING,
  GET_ORIGINAL_TRACK_REQUEST,
  GET_ORIGINAL_TRACK_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  isError: false,
  isLoading: false,
  error: undefined,
  track: [],
};

export const originalTrackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORIGINAL_TRACK_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
        track: [],
      };
    case GET_ORIGINAL_TRACK_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORIGINAL_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        track: action.track,
      };
    case GET_ORIGINAL_TRACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
