import {
  CANCEL_SUB_FAILURE,
  CANCEL_SUB_LOADING,
  CANCEL_SUB_REQUEST,
  CANCEL_SUB_SUCCESS,
  CREATE_SUB_FAILURE,
  CREATE_SUB_LOADING,
  CREATE_SUB_SUCCESS,
  GET_CLIENT_SECRET_FAILURE,
  GET_CLIENT_SECRET_LOADING,
  GET_CLIENT_SECRET_SUCCESS,
  GET_PB_KEY_FAILURE,
  GET_PB_KEY_LOADING,
  GET_PB_KEY_SUCCESS,
  GET_PLANS_FAILURE,
  GET_PLANS_LOADING,
  GET_PLANS_SUCCESS,
  RESET_CREATE_SUB,
  RESET_GET_CLIENT,
  RESET_PB_KEY,
  SET_SELECTED_PLAN,
  GET_SUB_BY_PROFILE_LOADING,
  GET_SUB_BY_PROFILE_SUCCESS,
  GET_SUB_BY_PROFILE_FAILURE,
  GET_SUB_DETAILS_LOADING,
  GET_SUB_DETAILS_SUCCESS,
  GET_SUB_DETAILS_FAILURE,
  UPDATE_PAYMENT_METHOD_LOADING,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILURE,
  SUB_PRO_ANALYSIS_FAILURE,
  SUB_PRO_ANALYSIS_SUCCESS,
  SUB_PRO_ANALYSIS_LOADING,
  GET_PRO_ANALYSIS_FAILURE,
  GET_PRO_ANALYSIS_SUCCESS,
  GET_PRO_ANALYSIS_LOADING,
} from "../actions/actionTypes";

export const paymentReducer = (
  state = {
    selectedPlan: null,
    PbKeyReducer: {
      isError: false,
      isLoading: false,
      Key: "",
      error: undefined,
    },
    getPlansReducer: {
      isError: false,
      isLoading: false,
      plans: [],
      error: undefined,
    },
    getClientSecretReducer: {
      isError: false,
      isLoading: false,
      secret: "",
      error: undefined,
    },
    createSubReducer: {
      isError: false,
      isLoading: false,
      result: [],
      error: undefined,
    },
    cancelSubReducer: {
      cancelled: [],
      isError: false,
      isLoading: false,
      error: undefined,
    },
    getSubByProfileReducer: {
      isError: false,
      isLoading: false,
      data: [],
      error: undefined,
    },
    getSubDetailsReducer: {
      isError: false,
      isLoading: false,
      data: [],
      error: undefined,
    },
    updatePaymentMethodReducer: {
      isError: false,
      isLoading: false,
      data: null,
      error: undefined,
    },
    getProAnalysisReducer: {
      isError: false,
      isLoading: false,
      data: [],
      error: undefined,
    },
    subProAnalysisReducer: {
      isError: false,
      isLoading: false,
      data: [],
      error: undefined,
    },
  },
  action
) => {
  switch (action.type) {
    case SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case GET_PB_KEY_LOADING:
      return {
        ...state,
        PbKeyReducer: {
          key: "",
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case GET_PB_KEY_SUCCESS:
      return {
        ...state,
        PbKeyReducer: {
          key: action.key,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_PB_KEY_FAILURE:
      return {
        ...state,
        PbKeyReducer: {
          key: "",
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case GET_PLANS_LOADING:
      return {
        ...state,
        getPlansReducer: {
          plans: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        getPlansReducer: {
          plans: action.plans,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_PLANS_FAILURE:
      return {
        ...state,
        getPlansReducer: {
          plans: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case GET_CLIENT_SECRET_LOADING:
      return {
        ...state,
        getClientSecretReducer: {
          secret: "",
          isLoading: false,
          isError: true,
          error: undefined,
        },
      };
    case GET_CLIENT_SECRET_SUCCESS:
      return {
        ...state,
        getClientSecretReducer: {
          secret: action.secret,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_CLIENT_SECRET_FAILURE:
      return {
        ...state,
        getClientSecretReducer: {
          secret: "",
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case CREATE_SUB_LOADING:
      return {
        ...state,
        createSubReducer: {
          result: [],
          isError: false,
          isLoading: true,
          error: undefined,
        },
      };
    case CREATE_SUB_SUCCESS:
      return {
        ...state,
        createSubReducer: {
          result: action.result,
          isError: false,
          isLoading: false,
          error: undefined,
        },
      };
    case CREATE_SUB_FAILURE:
      return {
        ...state,
        createSubReducer: {
          result: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case CANCEL_SUB_LOADING:
      return {
        ...state,
        cancelSubReducer: {
          cancelled: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case CANCEL_SUB_SUCCESS:
      return {
        ...state,
        cancelSubReducer: {
          cancelled: action.cancelled,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case CANCEL_SUB_FAILURE:
      return {
        ...state,
        cancelSubReducer: {
          cancelled: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case RESET_PB_KEY:
      return {
        PbKeyReducer: {
          isError: false,
          isLoading: false,
          error: undefined,
          key: "",
        },
      };
    case RESET_GET_CLIENT:
      return {
        getClientSecretReducer: {
          isError: false,
          isLoading: false,
          error: undefined,
          secret: "",
        },
      };
    case RESET_CREATE_SUB:
      return {
        createSubReducer: {
          isError: false,
          isLoading: false,
          error: undefined,
          result: [],
        },
      };
    case GET_SUB_BY_PROFILE_LOADING:
      return {
        ...state,
        getSubByProfileReducer: {
          data: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case GET_SUB_BY_PROFILE_SUCCESS:
      return {
        ...state,
        getSubByProfileReducer: {
          data: action.data,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_SUB_BY_PROFILE_FAILURE:
      return {
        ...state,
        getSubByProfileReducer: {
          data: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case GET_SUB_DETAILS_LOADING:
      return {
        ...state,
        getSubDetailsReducer: {
          data: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case GET_SUB_DETAILS_SUCCESS:
      return {
        ...state,
        getSubDetailsReducer: {
          data: action.data,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_SUB_DETAILS_FAILURE:
      return {
        ...state,
        getSubDetailsReducer: {
          data: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case UPDATE_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        updatePaymentMethodReducer: {
          data: null,
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        updatePaymentMethodReducer: {
          data: action.data,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case UPDATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        updatePaymentMethodReducer: {
          data: null,
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case GET_PRO_ANALYSIS_LOADING:
      return {
        ...state,
        getProAnalysisReducer: {
          data: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case GET_PRO_ANALYSIS_SUCCESS:
      return {
        ...state,
        getProAnalysisReducer: {
          data: action.data,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_PRO_ANALYSIS_FAILURE:
      return {
        ...state,
        getProAnalysisReducer: {
          data: [],
          isLoading: false,
          isError: true,
          error: action.payload,
        },
      };
    case SUB_PRO_ANALYSIS_LOADING:
      return {
        ...state,
        subProAnalysisReducer: {
          data: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case SUB_PRO_ANALYSIS_SUCCESS:
      return {
        ...state,
        subProAnalysisReducer: {
          data: action.data,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case SUB_PRO_ANALYSIS_FAILURE:
      return {
        ...state,
        subProAnalysisReducer: {
          data: [],
          isLoading: false,
          isError: true,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
