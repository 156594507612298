import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ORIGINAL_TRACK_FAILURE,
  GET_ORIGINAL_TRACK_LOADING,
  GET_ORIGINAL_TRACK_REQUEST,
  GET_ORIGINAL_TRACK_SUCCESS,
} from "../actions/actionTypes";
import { getSingleTrackApi } from "../../api/track/getSingleTrack";

function* getOriginalTrack(action) {
  try {
    yield put({ type: GET_ORIGINAL_TRACK_LOADING });
    const trackId = action.payload;
    const track = yield call(getSingleTrackApi, trackId);
    yield put({ type: GET_ORIGINAL_TRACK_SUCCESS, track });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message || "Failed to fetch track details"
      : "An unexpected error occurred";
    yield put({ type: GET_ORIGINAL_TRACK_FAILURE, errorMessage });
  }
}

export default function* getOriginalTrackSaga() {
  yield takeLatest(GET_ORIGINAL_TRACK_REQUEST, getOriginalTrack);
}
