import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import "./ProgressSteps.css";

const CustomStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepConnector-root": {
    height: 2,
  },
  "& .MuiStepConnector-line": {
    height: 2,
    backgroundColor: "#fff", // Default line color for uncompleted steps
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "#23f0c7",
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: "#23f0c7",
  },
}));

export default function HorizontalLinearAlternativeLabelStepper(props) {
  const steps = props.upgrade
  ? [props.serviceType ? "Service" : "Choose Plan", "Payment", "Success"]
  : props.membPlan === "free"
  ? ["Choose Plan", "Sign Up", "About You", "Success"]
  : ["Choose Plan", "Sign Up", "About You", "Payment", "Success"];
  const activeStep = parseInt(props.step);
  return (
    <Box sx={{ width: "100%" }}>
      <CustomStepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={({ active, completed }) => {
                let icon;

                if (completed) {
                  icon = (
                    <div
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        backgroundColor: "#23f0c7", // Filled circle color
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckIcon style={{ color: "white", fontSize: 16 }} />
                    </div>
                  );
                } else if (active) {
                  icon = (
                    <div
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        backgroundColor: "white", // Active circle background
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `1px solid #23f0c7`, // Border color for active step
                        padding: 2, // Space between the dot and the circle
                      }}
                    >
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          backgroundColor: "#23f0c7", // Dot color for active step
                        }}
                      />
                    </div>
                  );
                } else {
                  icon = (
                    <div
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        backgroundColor: "white", // Circle background for remaining steps
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `1.5px solid #ccc`, // Border for remaining steps
                      }}
                    />
                  );
                }

                return (
                  <div style={{ position: "relative", width: 24, height: 24 }}>
                    {icon}
                    {index > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "0",
                          width: "100%",
                          height: 2,
                          backgroundColor: completed ? "#23f0c7" : "#000",
                          zIndex: -1,
                        }}
                      />
                    )}
                  </div>
                );
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </CustomStepper>
    </Box>
  );
}
