import "../../../styles/Fonts.scss";
import "../../SignUpPage/SignUpPage.css";
import SMLButton from "../../../components/Shared/SMLButton/SMLButton";
import { useNavigate } from "react-router-dom";
import "./Welcome.css";
import ProgressSteps from "../ProgressSteps/ProgressSteps";
import SuccessCheckMark from "../../../assets/Images/SuccessCheckmark.svg";
import { useDispatch } from "react-redux";
import {
  artistSignupRemove,
  artistShowLogin,
} from "../../../redux/actions/authActions";
import closeIcon from "../../../assets/Images/closeIcon.svg";

const Welcome = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleHomeClick = () => {
    props.closePopup();
    dispatch(artistSignupRemove());
  };
  const handleLoginClick = () => {
    dispatch(artistShowLogin());
    props.closePopup();
  };

  const handleClose = () => {
    navigate("/login");
  };

  return (
    <div>
      <div className="">
        <div className="signUp-progress-steps">
          {props.upgradePlan ? (
            <ProgressSteps step="3" upgrade="true" />
          ) : (
            <ProgressSteps membPlan={props.membPlan} step="4" />
          )}
        </div>
        <div className="signUp-close-div" onClick={props.closePopup}>
          <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
        </div>
      </div>
      <div className="signUp-welcome-main">
        <div className="welcome-success-checkMark">
          <span className="sml-iconbtn fontcolorblack">
            <img src={SuccessCheckMark} alt="icon" />
          </span>
        </div>
        <div className="Welcome-label-div">
          <div className="Welcome-Label-text Mainheader">Welcome On Board!</div>
          {props.signupMethod === 'email' ? (
            <div className="Welcome-label-track subtitle3" style={{textAlign: "center"}}>
              We've sent a verification link to your email address. Please check your inbox and click the link to confirm your email. Once verified, you'll be able to log in and submit your first track.
            </div>
          ) : (
            <div className="Welcome-label-track subtitle3" style={{textAlign: "center"}}>
              Your account has been created successfully! You can now log in and start submitting your tracks.
            </div>
          )}
          <div className="Welcome-btn-grp" style={{marginTop: "0"}}>
            <SMLButton
              style="SMLButton2 SMLButton-Orange"
              type="button"
              value="Login"
              handleClickEvent={handleLoginClick}
            />
            <div
              onClick={handleHomeClick}
              style={{cursor: "pointer"}}
            >
              Home
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
