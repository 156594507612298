import { call, put, takeLatest } from "redux-saga/effects";
import {
  DELETE_PROFILE_FAILURE,
  DELETE_PROFILE_LOADING,
  DELETE_PROFILE_REQUEST,
  DELETE_PROFILE_SUCCESS,
} from "../actions/actionTypes";
import { deleteArtistProfileApi } from "../../api/artists/deleteArtistApi";

function* deleteArtistProfile(action) {  // Add action parameter
  try {
    yield put({ type: DELETE_PROFILE_LOADING });
    const details = yield call(deleteArtistProfileApi, action.payload);  // Pass the payload
    yield put({ type: DELETE_PROFILE_SUCCESS, details });
  } catch (error) {
    yield put({ type: DELETE_PROFILE_FAILURE, error });
  }
}

function* deleteProfileSaga() {
  yield takeLatest(DELETE_PROFILE_REQUEST, deleteArtistProfile);
}

export default deleteProfileSaga;
