import React, { useState, useRef, useEffect } from "react";
import {
  Stack,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  FiberManualRecord,
  Stop,
  Refresh,
  Close,
  Save,
  Delete,
  Settings,
  CheckCircle,
  Upload,
} from "@mui/icons-material";
import "./Recorder.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getVideoSignedUrlRequest,
  getVideoUrlRequest,
  deleteVideoRequest,
  uploadVideoRequest,
} from "../../../redux/actions/videoActions";
import * as types from "../../../redux/actions/actionTypes";
import { getFeedbackRequest } from "../../../redux/actions";
import { Bars } from "react-loader-spinner";

const Recorder = ({ onClose, trackId, feedbackId: initialFeedbackId, existingVideoUrl }) => {
  const dispatch = useDispatch();
  const signedUrlData = useSelector((state) => state.video?.signedUrlData);
  const isLoading = useSelector((state) => state.video?.isLoading);
  const isError = useSelector((state) => state.video?.isError);
  const videoState = useSelector((state) => {
    return state.video;
  });
  const [isRecording, setIsRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [uploadedVideoUrl, setUploadedVideoUrl] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedAudio, setSelectedAudio] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const [savedVideoId, setSavedVideoId] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("getting-url");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [feedbackId, setFeedbackId] = useState(initialFeedbackId);
  const [position, setPosition] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const recorderRef = useRef(null);

  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const combinedStreamRef = useRef(null);
  const chunksRef = useRef([]);
  const fileInputRef = useRef(null);

  // Mock API endpoints (you'll replace these later)
  const MOCK_UPLOAD_URL = "/api/upload";
  const MOCK_DELETE_URL = "/api/delete";

  // Get the entire signed URL response from Redux
  const signedUrlResponse = useSelector((state) => state.video?.signedUrlData);


  useEffect(() => {
    async function getDevices() {
      try {
        // First request permission to access media devices
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        }).catch(err => {
          console.log("Permission denied or no devices available:", err);
          // Still continue to check for devices even if permission denied
          return null;
        });

        // Stop the test stream if we got one
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }

        // Wait a brief moment for devices to be properly enumerated
        setTimeout(async () => {
          try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videos = devices.filter(
              (device) => device.kind === "videoinput"
            );
            const audios = devices.filter(
              (device) => device.kind === "audioinput"
            );
            setVideoDevices(videos);
            setAudioDevices(audios);

            // Set defaults if available
            if (videos.length) {
              setSelectedVideo(videos[0].deviceId);
            }
            if (audios.length) {
              setSelectedAudio(audios[0].deviceId);
            }
          } catch (enumError) {
            console.error("Error enumerating devices:", enumError);
            // Set empty arrays if we can't get devices
            setVideoDevices([]);
            setAudioDevices([]);
          }
        }, 500);
      } catch (err) {
        console.error("Error getting devices:", err);
        // Set empty arrays if we can't get permission
        setVideoDevices([]);
        setAudioDevices([]);
      }
    }

    getDevices();

    // Add listener for device changes
    navigator.mediaDevices.addEventListener("devicechange", getDevices);

    return () => {
      stopMediaTracks();
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, []);

  const startRecording = async () => {
    try {
      // First get screen sharing with system audio
      const screenStream = await navigator.mediaDevices
        .getDisplayMedia({
          video: {
            cursor: "always",
            displaySurface: "browser",
          },
          audio: {
            autoGainControl: false,
            echoCancellation: false,
            noiseSuppression: false,
            channelCount: 2,
            sampleRate: 44100,
          },
        })
        .catch((err) => {
          console.error("Screen share error:", err.name, err.message);
          throw err;
        });
      const micStream = await navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: {
            deviceId: selectedAudio ? { exact: selectedAudio } : undefined,
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .catch((err) => {
          console.error("Microphone error:", err.name, err.message);
          screenStream.getTracks().forEach((track) => track.stop());
          throw err;
        });
      const webcamStream = await navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: selectedVideo ? { exact: selectedVideo } : undefined,
          },
          audio: false,
        })
        .catch((err) => {
          console.error("Webcam error:", err.name, err.message);
          screenStream.getTracks().forEach((track) => track.stop());
          micStream.getTracks().forEach((track) => track.stop());
          throw err;
        });
      // Show webcam preview
      if (videoRef.current) {
        videoRef.current.srcObject = webcamStream;
      }

      // Combine all tracks
      const tracks = [
        ...screenStream.getVideoTracks(),
        ...webcamStream.getVideoTracks(),
        ...screenStream.getAudioTracks(),
        ...micStream.getAudioTracks(),
      ];
      combinedStreamRef.current = new MediaStream(tracks);

      // Get supported mime type
      const mimeType = getSupportedMimeType();

      // Create and start MediaRecorder
      const mediaRecorder = new MediaRecorder(combinedStreamRef.current, {
        mimeType: mimeType,
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
      });
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: mimeType });
        setRecordedBlob(blob);
        stopMediaTracks();
      };

      mediaRecorder.start(1000);
      setIsRecording(true);
    } catch (err) {
      console.error("Error starting recording:", err.name, err.message);
      stopMediaTracks();
    }
  };

  const stopMediaTracks = () => {
    if (combinedStreamRef.current) {
      combinedStreamRef.current.getTracks().forEach((track) => {
        track.stop();
        combinedStreamRef.current.removeTrack(track);
      });
      combinedStreamRef.current = null;
    }
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const getSupportedMimeType = () => {
    const types = [
      "video/webm;codecs=vp9,opus",
      "video/webm;codecs=h264,opus",
      "video/webm;codecs=vp8,opus",
      "video/webm",
      "video/mp4",
    ];
    return (
      types.find((type) => MediaRecorder.isTypeSupported(type)) || "video/webm"
    );
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleSave = async () => {
    if (!recordedBlob && !selectedFile) {
      console.error("No file to upload");
      return;
    }

    try {
      setIsUploading(true);
      const file = selectedFile || recordedBlob;
      const fileName = `video_${Date.now()}.${file.type.split("/")[1]}`;

      const payload = {
        track_id: trackId,
        file_name: fileName,
        file_type: file.type,
      };

      dispatch(getVideoSignedUrlRequest(payload));
    } catch (error) {
      console.error("Error in handleSave:", error);
      setIsUploading(false);
    }
  };

  const handleDelete = async () => {
    try {
      if (feedbackId) {
        dispatch(deleteVideoRequest(feedbackId));
        setUploadedVideoUrl(null);
        setRecordedBlob(null);
      } else {
        console.error("No feedback ID available for deletion");
      }
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };

  const handleRestart = () => {
    // Cleanup Blob URL if exists
    if (uploadedVideoUrl) {
      URL.revokeObjectURL(uploadedVideoUrl);
    }

    // Reset all states
    setRecordedBlob(null);
    setUploadedVideoUrl(null);
    setSavedVideoId(null);
    setIsRecording(false);
    chunksRef.current = [];

    // Clean up media streams
    stopMediaTracks();

    // Reset MediaRecorder
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current = null;
    }

    // Reset video preview
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  const toggleSettings = () => setShowSettings(!showSettings);

  const handleSuccessClick = () => {
    // Show the preview of the uploaded video
    dispatch(getVideoUrlRequest(newFeedbackId));
    setShowSuccessMessage(false);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if file is video
      if (!file.type.startsWith("video/")) {
        alert("Please select a video file");
        return;
      }

      // Check file size (e.g., 100MB limit)
      if (file.size > 500 * 1024 * 1024) {
        alert("File size should be less than 500MB");
        return;
      }

      const blobUrl = URL.createObjectURL(file);
      setRecordedBlob(file); // Use same state as recording
      setSelectedFile(file);
    }
  };

  // Also cleanup on component unmount
  useEffect(() => {
    return () => {
      if (uploadedVideoUrl) {
        URL.revokeObjectURL(uploadedVideoUrl);
      }
    };
  }, [uploadedVideoUrl]);

  // Use existingVideoUrl if provided
  useEffect(() => {
    if (existingVideoUrl) {
      setUploadedVideoUrl(existingVideoUrl);
    }
  }, [existingVideoUrl]);

  // Add useEffect to handle signedUrl changes
  useEffect(() => {
    const uploadToSignedUrl = async () => {
      if (!signedUrlData?.upload_url || !selectedFile) return;

      try {
        const file = selectedFile || recordedBlob;
        const xhr = new XMLHttpRequest();
        
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;
            setUploadProgress(progress);
          }
        };

        await new Promise((resolve, reject) => {
          xhr.open('PUT', signedUrlData.upload_url);
          xhr.setRequestHeader('Content-Type', file.type);
          
          xhr.onload = () => {
            if (xhr.status === 200) {
              resolve();
            } else {
              reject(new Error(`Upload failed: ${xhr.status}`));
            }
          };
          
          xhr.onerror = () => reject(new Error('Upload failed'));
          xhr.send(file);
        });

        setShowSuccessMessage(true);
        setSelectedFile(null);
        setRecordedBlob(null);
        setIsUploading(false);
        setUploadProgress(0);
      } catch (error) {
        console.error("❌ Upload failed:", error);
        setIsUploading(false);
        setUploadProgress(0);
      }
    };

    uploadToSignedUrl();
  }, [signedUrlData, selectedFile, recordedBlob]);

  useEffect(() => {
    getFeedbackRequest(trackId);
  }, []);
  const newFeedbackId = useSelector(
    (state) =>
      state.getFeedback?.feedback?.[0]?._id ||
      state.video?.signedUrlData?.feedback_id
  );
  useEffect(() => {
    if (newFeedbackId) {
      setFeedbackId(newFeedbackId);
      dispatch(getVideoUrlRequest(newFeedbackId));
    }
  }, [newFeedbackId]);
  const videoUrl = useSelector(
    (state) => state.video?.signedUrlData?.video_url
  );
  useEffect(() => {}, [videoUrl]);

  const handleMouseDown = (e) => {
    // Ignore if clicking on buttons or controls
    if (e.target.closest('.close-button, .settings-button, .controls')) {
      return;
    }

    const rect = recorderRef.current.getBoundingClientRect();
    setDragOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    });
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const x = e.clientX - dragOffset.x;
    const y = e.clientY - dragOffset.y;

    recorderRef.current.style.left = `${x}px`;
    recorderRef.current.style.top = `${y}px`;
    recorderRef.current.style.right = 'auto';
    recorderRef.current.style.bottom = 'auto';
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <Box
      ref={recorderRef}
      className={`recorder-container ${isDragging ? 'dragging' : ''}`}
      onMouseDown={handleMouseDown}
    >
      <IconButton className="close-button" onClick={onClose}>
        <Close />
      </IconButton>

      <IconButton 
        className="settings-button" 
        onClick={toggleSettings}
        sx={{ padding: 0.7 }}
      >
        <Settings sx={{ width: 20, height: 20 }} />
      </IconButton>

      {showSettings && !isRecording && !recordedBlob && !uploadedVideoUrl && (
        <Stack spacing={2} className="settings-controls">
          <div style={{ color: 'white' }}>Settings Panel</div>
          
          {videoDevices.length > 0 ? (
            <FormControl size="small">
              <InputLabel>Camera</InputLabel>
              <Select
                value={selectedVideo}
                onChange={(e) => setSelectedVideo(e.target.value)}
                label="Camera"
                MenuProps={{
                  container: document.body,
                  style: { zIndex: 9999 }
                }}
              >
                {videoDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Camera ${device.deviceId.slice(0, 5)}...`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div style={{ color: 'white' }}>No video devices found</div>
          )}

          {audioDevices.length > 0 ? (
            <FormControl size="small">
              <InputLabel>Microphone</InputLabel>
              <Select
                value={selectedAudio}
                onChange={(e) => setSelectedAudio(e.target.value)}
                label="Microphone"
                MenuProps={{
                  container: document.body,
                  style: { zIndex: 9999 }
                }}
              >
                {audioDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Microphone ${device.deviceId.slice(0, 5)}...`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div style={{ color: 'white' }}>No audio devices found</div>
          )}
        </Stack>
      )}

      {showSuccessMessage ? (
        <div className="success-message" onClick={handleSuccessClick}>
          <div className="success-content">
            <CheckCircle sx={{ color: "success.main", fontSize: 40 }} />
            <Typography>Video upload successfully!</Typography>
            <Typography variant="caption">Click to view</Typography>
          </div>
        </div>
      ) : uploadedVideoUrl ? (
        <div className="recorded-preview">
          <video className="preview-window" src={uploadedVideoUrl} controls />
          <div className="controls">
            <IconButton onClick={handleRestart}>
              <Refresh />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <Delete />
            </IconButton>
          </div>
        </div>
      ) : recordedBlob ? (
        <div className="recorded-preview">
          <video
            className="preview-window"
            src={URL.createObjectURL(recordedBlob)}
            controls
          />
          <div className="controls">
            <IconButton onClick={handleRestart}>
              <Refresh />
            </IconButton>
            <IconButton 
              onClick={handleSave} 
              disabled={isUploading}
            >
              <Save />
            </IconButton>
          </div>
        </div>
      ) : (
        <>
          <video
            ref={videoRef}
            className="preview-window"
            autoPlay
            muted
            playsInline
          />
          <div className="record-controls">
            <input
              type="file"
              ref={fileInputRef}
              accept="video/*"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
            <Tooltip title="Upload Video">
              <IconButton
                className="upload-button"
                onClick={() => fileInputRef.current.click()}
              >
                <Upload />
              </IconButton>
            </Tooltip>
            <Tooltip title="Record Video">
              <IconButton
                className="record-button"
                onClick={() => {
                  if (isRecording) {
                    stopRecording();
                  } else {
                    startRecording();
                  }
                }}
              >
                {isRecording ? <Stop /> : <FiberManualRecord />}
              </IconButton>
            </Tooltip>
          </div>
          {isRecording && <div className="recording-indicator" />}
        </>
      )}
      {isUploading && (
        <div className="upload-overlay">
          <div className="upload-progress-container">
            <div className="upload-text">
              Uploading... {Math.round(uploadProgress)}%
            </div>
          </div>
          <div className="progress-bar">
            <div 
              className="progress-bar-fill" 
              style={{ width: `${uploadProgress}%` }} 
            />
          </div>
        </div>
      )}
    </Box>
  );
};

export default Recorder;
