import React from "react";
import ReactDOM from "react-dom/client";

import { APPCONFIG } from "./config/APPConfig";
import "./index.css";
import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.css";
import GoogleTagManager from "./components/GoogleTagManager";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import smlReducers from "./redux/reducers/smlReducers";
import smlSaga from "./redux/sagas/smlSagas";
import authReducer from "./redux/reducers/authReducer";
import uploadTrackReducer from "./redux/reducers/uploadTrackReducer";
import authSaga from "./redux/sagas/authSaga";
import watchUploadTrack from "./redux/sagas/uploadTrackSaga";
import { all } from "redux-saga/effects";
import { searchReducer } from "./redux/reducers/searchReducers";
import searchSaga from "./redux/sagas/searchSaga";
import trackStatsReducer from "./redux/reducers/trackStatsReducer";
import trackStatsSaga from "./redux/sagas/trackStatsSaga";
import { assignSupervisorReducer } from "./redux/reducers/assignSupervisorReducer";
import assignSupervisorSaga from "./redux/sagas/assignSupervisorSaga";
import {
  getFeedbackReducer,
  postFeedbackReducer,
  putFeedbackMsReducer,
  putFeedbackReducer,
  putFinalFeedbackMsReducer,
} from "./redux/reducers/postFeedbackReducer";
import {
  getFeedbackSaga,
  postFeedbackSaga,
  putFeedbackMsSaga,
  putFeedbackSaga,
  putFinalFeedbackMsSaga,
} from "./redux/sagas/postFeedbackSaga";
import { downloadTrackReducer } from "./redux/reducers/downloadTrackReducers";
import { downloadTrackSaga } from "./redux/sagas/downloadTrackSaga";
import { singleArtistReducer } from "./redux/reducers/artistReducers";
import { getSingleArtistSaga } from "./redux/sagas/artistSaga";
import supervisorReducer from "./redux/reducers/supervisorReducers";
import supervisorSaga from "./redux/sagas/supervisorSaga";
import { paymentReducer } from "./redux/reducers/paymentReducers";
import paymentSaga from "./redux/sagas/paymentSaga";
import { forgetPassReducer } from "./redux/reducers/forgetPasswordReducers";
import forgetPasswordSaga from "./redux/sagas/forgetPasswordSaga";
import { confirmMailReducer } from "./redux/reducers/confirmMailReducers";
import confirmMailSaga from "./redux/sagas/confirmMailSaga";

import { websocketReducers } from "./redux/reducers/websocketReducers";
import websocketSaga from "./redux/sagas/websocketSaga";
import watchVideoConsultationSaga from "./redux/sagas/videoConsultationSaga";
import videoConsultationReducer from "./redux/reducers/videoConsultationReducers";
import getNotificationSaga from "./redux/sagas/notificationSaga";
import { getNotificationReducers } from "./redux/reducers/notificationReducers";
import videoSaga from "./redux/sagas/videoSaga";
import videoReducer from "./redux/reducers/videoReducer";
import uploadStatsReducer from "./redux/reducers/uploadStatsReducer";
import uploadStatsSaga from "./redux/sagas/uploadStatsSaga";
import { originalTrackReducer } from "./redux/reducers/originalTrackReducers";
import getOriginalTrackSaga from "./redux/sagas/originalTrackSaga";
import { deleteProfileReducer } from "./redux/reducers/deleteProfileReducer";
import deleteProfileSaga from "./redux/sagas/deleteProfileSaga";
const sagaMiddleware = createSagaMiddleware();

const root = ReactDOM.createRoot(document.getElementById("root"));
const rootReducers = combineReducers({
  sml: smlReducers,
  auth: authReducer,
  uploadTrack: uploadTrackReducer,
  originalTrack: originalTrackReducer,
  search: searchReducer,
  trackStats: trackStatsReducer,
  assignSupervisor: assignSupervisorReducer,
  getFeedback: getFeedbackReducer,
  postFeedback: postFeedbackReducer,
  putFeedback: putFeedbackReducer,
  putFeedbackMs: putFeedbackMsReducer,
  putFinalFeedbackMs: putFinalFeedbackMsReducer,
  downloadtrack: downloadTrackReducer,
  singleArtist: singleArtistReducer,
  supervisor: supervisorReducer,
  payment: paymentReducer,
  forgetPass: forgetPassReducer,
  confirmMail: confirmMailReducer,
  websocket: websocketReducers,
  videoConsultation: videoConsultationReducer,
  notifs: getNotificationReducers,
  video: videoReducer,
  uploadStats: uploadStatsReducer,
  deleteProfile: deleteProfileReducer,
});
// mount it on the Store
const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});
// then run the saga
sagaMiddleware.run(function* rootSaga() {
  yield all([
    authSaga(),
    smlSaga(),
    watchUploadTrack(),
    searchSaga(),
    trackStatsSaga(),
    assignSupervisorSaga(),
    getFeedbackSaga(),
    postFeedbackSaga(),
    putFeedbackSaga(),
    putFeedbackMsSaga(),
    putFinalFeedbackMsSaga(),
    downloadTrackSaga(),
    getSingleArtistSaga(),
    supervisorSaga(),
    paymentSaga(),
    forgetPasswordSaga(),
    confirmMailSaga(),
    websocketSaga(),
    watchVideoConsultationSaga(),
    getNotificationSaga(),
    videoSaga(),
    uploadStatsSaga(),
    getOriginalTrackSaga(),
    deleteProfileSaga(),
  ]);
});

root.render(
  <GoogleOAuthProvider clientId={APPCONFIG.GOOGLE_APP_ID}>
    {/* <React.StrictMode> */}
    <Router>
      <Provider store={store}>
        <GoogleTagManager />
        <App />
      </Provider>
    </Router>
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
