import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;

export const subProAnalysisApi = async (data) => {
  try {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }

    const url = `${BASE_URL}/billing/payments`;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    
    const payload = {
      trackId: data.trackId,
      priceId: data.priceId,
      paymentMethodId: data.paymentMethodId,
      serviceType: data.serviceType,
    };

    const response = await api.post(url, payload, config);
    return response.data;
  } catch (error) {
    console.error("error in getting subscription by profile", error.message);
    throw error;
  }
};