import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;
// Function to cancel subscriptions
export const cancelSubApi = async (data) => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }
    console.log("data", data)
    const url = `${BASE_URL}/subscriptions/cancel`;
    let config = {
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await api.post(url, data?.id, config);
    return response.data;
  } catch (error) {
    console.error("Error in cancelling subscription", error.message);
    throw error;
  }
};
