import React from 'react';
import checkBox from "../../../assets/Images/checkboxIcon.svg";

const PricingPlanCard = ({ planData, isYearly }) => {
  return (
    <div className="payment-plan-card">
      <div className="plan-card-content">
        <h2 className="title1">{planData?.name}</h2>
        <div className="payment-price-container">
          <p className="plan-price">
            {isYearly ? planData?.yearlyPrice : planData?.price}
            <span className="plan-duration">
            {planData?.name === "Track Analysis" ||
              planData?.name === "Video-Consultation Services"
                ? "/per track"
                : isYearly
                ? "/year"
                : "/month"}
            </span>
          </p>
        </div>
        <div className="horizontal-line"></div>
        <ul className="features-list">
          {planData?.features.map((feature, i) => {
            if (typeof feature === "string") {
              return (
                <li key={i} className="features-item">
                  <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                  <span className="feature-text">{feature}</span>
                </li>
              );
            } else if (typeof feature === "object") {
              const [title, nestedFeatures] = Object.entries(feature)[0];
              return (
                <React.Fragment key={i}>
                  <li className="features-item">
                    <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                    <span className="feature-text">{title}</span>
                  </li>
                  <ul className="nested-features-list">
                    {nestedFeatures.map((nestedFeature, j) => (
                      <li key={`${i}-${j}`} className="nested-feature-item">
                        <span className="bullet-icon">•</span>
                        <span className="feature-text">{nestedFeature}</span>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default PricingPlanCard; 