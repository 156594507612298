import React, { useState, useEffect } from "react";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import closeIcon from "../../assets/Images/closeIcon.svg";
import "../../styles/Fonts.scss";
import "../../pages/Feedback/feedback.css";
import "../../pages/LoginPage/LoginPage.css";
import Backbutton from "../../components/Shared/BackButton/BackButton";
import SMLTextArea from "../../components/Shared/SMLTextArea/SMLTextArea";

const TargetOptions = [
  { id: "option1", label: "TV", value: "tv" },
  { id: "option2", label: "Film", value: "film" },
  { id: "option3", label: "Trailers & Film/TV Marketing", value: "trailers" },
  { id: "option4", label: "Commercials", value: "commercials" },
  { id: "option5", label: "Video Game", value: "video_game" },
  { id: "option6", label: "Indie Label", value: "indie_label" },
];
const FeedbackStageThree = (props) => {
  const {
    feedbackOptions,
    targetOptions,
    handleFeedbackOptions,
    handleTargetOptions,
  } = props;
  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    const anyFeedbackOptionSelected = feedbackOptions.length > 0;
    const anyTargetOptionSelected = targetOptions.length > 0;
    setIsContinueEnabled(anyTargetOptionSelected);
  }, [feedbackOptions, targetOptions]);
  const handleClickOptionFeedback = (label) => {
    const newFeedbackOptions = feedbackOptions.includes(label)
      ? feedbackOptions.filter((option) => option !== label)
      : [...feedbackOptions, label];
    handleFeedbackOptions(newFeedbackOptions);
  };
  const handleClickOptionTarget = (label) => {
    const newTargetOptions = targetOptions.includes(label)
      ? targetOptions.filter((option) => option !== label)
      : [...targetOptions, label];
    handleTargetOptions(newTargetOptions);
  };
  function containsXSS(input) {
    const xssPattern =
      /<\s*\/?\s*([a-z][a-z0-9]*)\b[^>]*>(?:[^<]*|<(?!\/?\1\b)[^<]*>)*<\/\1>/gi;
    return xssPattern.test(input);
  }
  const handleClick = (e) => {
    e.preventDefault();
    if (containsXSS(props.additionalInfo)) {
      setError("not valid format");
    } else {
      setError("");
      props.onFeedbackClick();
    }
  };

  const handleContinueClick = () => {
    if (containsXSS(props.additionalInfo)) {
      setError("Not valid format");
    } else {
      setError("");
      props.onFeedbackClick();
    }
  };

  const popupRef =props.popupRef

  return (
    <div className="feedback-container">
      <div className="feedback-card" ref={popupRef}>
        <div className="feedback-close-div">
          <img src={closeIcon} alt="Close" onClick={props.onCloseTrack} />
        </div>
        <div className="feedback-main">
          <div className="feedback-label-div">
            <SMLLabel
              value="Submit Track For Feedback"
              style="Mainheader"
              resTitle="true"
            />
          </div>
          <form className="feedback-content" onSubmit={handleClick}>
            <div>
              

              <div className="feedback-fields">
                <SMLLabel
                  value="Target for tracks placement opportunities"
                  style="title2"
                />
                <div className="form-group row-4 me-2">
                  {TargetOptions.map((item) => (
                    <SMLOptionQuestion
                      key={item.id}
                      type="button"
                      style={
                        targetOptions.includes(item.label)
                          ? "SMLSecondaryButtonSelected mt-1 me-2"
                          : "SMLSecondaryButton mt-1 me-2"
                      }
                      handleClickEvent={() =>
                        handleClickOptionTarget(item.label)
                      }
                      value={item.label}
                    />
                  ))}
                </div>
              </div>
              <div>
                <SMLLabel
                  value="Is there anything else you'd like us to know?"
                  style="title2 mb-2"
                />
                <SMLTextArea
                  value={props.additionalInfo}
                  onChange={(e) => {
                    props.handleAdditionalInfo(e.target.value);
                  }}
                />
              </div>
              {error && <SMLLabel style="error" value={error} />}
            </div>
            <div className="feedback-btn-container">
              <div
                className="feedback-btn-Bottom-left"
                onClick={props.handleBackClick}
              >
                <Backbutton />
              </div>
              <div className="feedback-btn-Bottom-right">
                <SMLButton
                  style={`SMLButton2 SMLButton-Orange ${
                    isContinueEnabled ? "" : "disabled"
                  }`}
                  value="Continue"
                  handleClickEvent={handleContinueClick}
                  handleDisable={!isContinueEnabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeedbackStageThree;
