import React, { useEffect, useState } from "react";
import HomeLayout from "../../components/Layout/HomeLayout";
import StyledHeader from "../../components/common/styled-components/StyledHeader";
import Styles from "./Dashboard.module.css";
import BannerImage from "../../assets/Images/banner-image.svg";
import RightArrow from "../../assets/Icons/right-arrow.svg";
import UploadIcon from "../../assets/Icons/upload-icon.svg";
import SMLCard from "../../components/common/styled-components/SMLCard";
import SMLCard2 from "../../components/common/styled-components/SMLCard2";
import TrackIcon from "../../assets/Icons/track-icon.svg";
import FeedbackIcon from "../../assets/Icons/feedback.svg";
import ReviewTrackIcon from "../../assets/Icons/review-tracks.svg";
import UploadedTracksIcon from "../../assets/Icons/uploaded-tracks.svg";
import UnderReviewIcon from "../../assets/Icons/under-review.svg";
import UploadTrack from "../../components/Shared/UploadTrack/UploadTrack";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import ReactPlayer from "react-player";
import video from "../../assets/video/ShannonIntro12.mp4";
import thumnail from "../../assets/Images/thumbnail.png";
import { FileDown } from 'lucide-react';
import {
  feedbackReceivedFetch,
  getAllArtistFetch,
  inReviewTracksFetch,
} from "../../redux/actions/smlActions";
import { getTrackStats } from "../../redux/actions/trackStats";
import { getAllTracksFetch } from "../../redux/actions/smlActions";
import {
  GET_UPLOAD_STATS_REQUEST,
  RESET_GET_ALL_TRACK,
  RESET_SEARCH,
} from "../../redux/actions/actionTypes";
import { useNavigate } from "react-router-dom";
import { artistSignupRemove } from "../../redux/actions/authActions";
import { Tooltip } from "react-tooltip";
import RestrictionPopup from "../../components/common/restricted-popup/RestrictionPopup";
import ChoosePlan from "../SignUpPage/ChoosePlan/ChoosePlan";
import { Modal } from "react-bootstrap";
import PaymentPopup from "../../components/common/payment-popup/PaymentPopup";
import ViewPlanSuccess from "../MyProfilePage/ViewPlan/ViewPlanSuccess";
import ResourceSection from '../../components/Resources/ResourceSection';


export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showRestricted, setShowRestricted] = useState(false);
  const [stats, setStats] = useState(null);
  const [track, setTrack] = useState(null);
  const [showChoosePlan, setShowChoosePlan] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [index, setIndex] = useState();
  const [plan, setPlan] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [isYearly, setIsYearly] = useState("");
  const [currentComponent, setCurrentComponent] = useState("plan");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [canUpload, setCanUpload] = useState(true);
  const [isPaidUser, setIsPaidUser] = useState(false);
  // const[addOnPlanClick, setAdd]

  const handleAddOnPlanClick = (plan, index) => {
    setSelectedPlanData(plan);
    setIsYearly(false);
    setIndex(index);
    setShowRestricted(false);
    setShowChoosePlan(true);
    setCurrentComponent("payment");
  };
  const handleTrackChange = (e) => {
    setTrack(e.target.files[0]);
  };

  useEffect(() => {
    // dispatch({ type: GET_UPLOAD_STATS_REQUEST });
    dispatch({ type: RESET_SEARCH });
    dispatch({ type: RESET_GET_ALL_TRACK });
    dispatch(getAllTracksFetch("submitted", "artist_id", 0, 5));
    dispatch(inReviewTracksFetch("in_review", "artist_id", 0, 5));
    dispatch(
      feedbackReceivedFetch("final_feedback_submitted", "artist_id", 0, 5)
    );
    dispatch(artistSignupRemove());
  }, [dispatch]);
  const uploadStats = useSelector(
    (state) => state.uploadStats?.statsFetched?.stats
  );
  const remainingUploads = useSelector(
    (state) => state.uploadStats.statsFetched?.stats?.remainingUploads
  );
  const isActive = useSelector(
    (state) => state.uploadStats?.statsFetched?.stats?.status
  );
  const uploadStatsLoading = useSelector(
    (state) => state.uploadStats?.statsFetched?.isLoading
  );

  const submittedTracks = useSelector(
    (store) => store.sml.allTracksFetched?.tracks
  );
  const inReviewTracks = useSelector(
    (store) => store.sml.underReviewTracks?.tracks?.data
  );
  const feedbackReceievedTracks = useSelector(
    (store) => store.sml.feedbackReceivedTracks?.tracks?.data
  );
  const submittedTracksLoading = useSelector(
    (store) => store.sml.allTracksFetched?.isLoading
  );
  const inReviewTracksLoading = useSelector(
    (store) => store.sml.underReviewTracks?.isLoading
  );
  const feedbackReceievedTracksLoading = useSelector(
    (store) => store.sml.feedbackReceivedTracks?.isLoading
  );

  const sml = useSelector((store) => store.sml);
  const trackStats = useSelector(
    (store) => store.trackStats.statsFetched?.stats
  );
  const loading = trackStats ? false : true;

  useEffect(() => {
    dispatch(getTrackStats("artist"));
  }, [dispatch]);

  const artists =
    useSelector((store) => store.sml.allArtistFetched?.artists) || [];

  useEffect(() => {
    if (artists.length === 0) {
      dispatch(getAllArtistFetch());
    }
  }, [dispatch]);

  useEffect(() => {
    if (trackStats) {
      setStats(trackStats);
    }
  }, [trackStats]);
  const handleUploadedTrackClick = () => {
    navigate("/uploadedTrack");
  };
  const handleInReviewClick = () => {
    navigate("/Review");
  };
  const handleFeedbackSubmittedClick = () => {
    navigate("/FeedbackReceived");
  };

  useEffect(() => {
    if (!uploadStatsLoading && artists && isActive !== "ACTIVE") {
      // setShowRestricted(true);
      console.log(artists?.membership_plan);
      setSubscribed(artists?.membership_plan);
    }

    if (uploadStats) {
      setIsPaidUser(uploadStats?.plan && uploadStats.plan !== 'FREE');
    }
    console.log('uploadStats', uploadStats, uploadStats?.plan && uploadStats.plan !== 'FREE');
    // setSubscribed(uploadStats?.status === "ACTIVE");
    // setPlan(isActive ? uploadStats?.plan : artists?.membership_plan);
    // setIsYearly(uploadStats?.interval === "YEARLY" ? true : false);
    // if (uploadStatsLoading) setShowRestricted(false);
    // const isRestricted = isActive !== "ACTIVE";
    // setCanUpload(!isRestricted);
    // setShowRestricted(isRestricted);
  }, [isActive, uploadStatsLoading, artists, remainingUploads, uploadStats?.plan]);

  const togglePopup = () => {
    if (!uploadStatsLoading) {
      if (
        isActive !== "ACTIVE" ||
        (isActive === "ACTIVE" && remainingUploads <= 0)
      ) {
        setShowRestricted(true);
        setSubscribed(uploadStats?.plan);
        setIsYearly(uploadStats?.interval === "YEARLY" ? true : false);
        return;
        // setIsYearly(uploadStats?.INTERVAL)
      }

      setPopupOpen(!isPopupOpen);
    }
  };
  const onNextClick1 = (plan, index, isYearly) => {
    setSelectedPlanData(plan);
    setIndex(index);
    setIsYearly(isYearly);
    setCurrentComponent("payment");
  };
  const onNextClick2 = () => {
    setCurrentComponent("welcome");
  };
  const handleCloseViewPlan = () => {
    // dispatch(getAllArtistFetch());
    // setShowChoosePlan(false);
    // setCurrentComponent("plan");
    // setIndex(null);
    // setSelectedPlanData(null);
    window.location.reload();
  };
  const handleBack = () => {
    setCurrentComponent("plan");
  };
  return (
    <HomeLayout applyScroll={true}>
      <StyledHeader>Dashboard</StyledHeader>
      <div className={Styles.dashboardContainer}>
        <BannerCard artists={artists} />
        <UploadCard onClick={togglePopup} isPaidUser={isPaidUser} />
        {isPopupOpen && <UploadTrack onPopupClose={togglePopup} />}

        <VideoCard />

        {!uploadStatsLoading && !loading && uploadStats ? (
          <div className={Styles.dashboardSubContainer}>
            {isPaidUser ? (
              <TrackSection 
                stats={stats}
                submittedTracks={submittedTracks}
                inReviewTracks={inReviewTracks}
                feedbackReceievedTracks={feedbackReceievedTracks}
                submittedTracksLoading={submittedTracksLoading}
                inReviewTracksLoading={inReviewTracksLoading}
                feedbackReceievedTracksLoading={feedbackReceievedTracksLoading}
                handleUploadedTrackClick={handleUploadedTrackClick}
                handleInReviewClick={handleInReviewClick}
                handleFeedbackSubmittedClick={handleFeedbackSubmittedClick}
              />
            ) : (
              <ResourceSection />
            )}
          </div>
        ) : (
          <div className={Styles.dashboardSubContainer}>
            <Bars
              height="80"
              width="80"
              color="#23f0c7"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!uploadStatsLoading && showRestricted && (
        // <>
        <RestrictionPopup
          handleAddOnPlanClick={handleAddOnPlanClick}
          showChoosePlan={showChoosePlan}
          setShowChoosePlan={setShowChoosePlan}
          onClose={() => {
            setShowRestricted(false);
          }}
        />
        // </>
      )}
      {showChoosePlan && (
        <Modal
          className="custom-modal"
          show={true}
          onHide={() => {
            if (currentComponent !== "payment") {
              setShowChoosePlan(false);
              setIndex(null);
              dispatch({ type: GET_UPLOAD_STATS_REQUEST });
              setCurrentComponent("plan");
            }
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {currentComponent === "plan" && (
            <ChoosePlan
              showFreePlan={false}
              subscribed={subscribed}
              // planWhileCreating={plan}
              upgradePlan={isActive === "ACTIVE" ? "upgrade" : "choose"}
              isYearly={isYearly}
              planIndex={index}
              closePopup={() => {
                setShowChoosePlan(false);
                setIndex(null);
              }}
              onNextClick1={onNextClick1}
              
            />
          )}
          {currentComponent === "payment" && (
            <PaymentPopup
              selectedPlan={selectedPlanData}
              closePopup={handleCloseViewPlan}
              nextClick={onNextClick2}
              backClick={handleBack}
              forUpgrade={true}
              isYearly={isYearly}
              
            />
          )}
          {currentComponent === "welcome" && (
            <ViewPlanSuccess
              upgradePlan="upgrade"
              closePopup={handleCloseViewPlan}
            />
          )}
        </Modal>
      )}
    </HomeLayout>
  );
}

const BannerCard = ({ artists }) => {
  return (
    <SMLCard className={Styles.bannerContainer}>
      <img
        alt="banner"
        src={BannerImage}
        className={Styles.bannerBackgroundImage}
      />
      <div className="bannerContainerHeading">
        <h3>
          {artists?.name}
          {" hub"}
        </h3>
      </div>
      <div>
        <img alt="right arrow" src={RightArrow} />
      </div>
    </SMLCard>
  );
};

const UploadCard = ({ onClick, isPaidUser }) => {
  return (
    <SMLCard className={`${Styles.uploadContainer} ${isPaidUser ? Styles.isPaidUser : ''}`} onClick={onClick}>
      {isPaidUser ? (
        <>
          <div>
            <img alt="upload" src={UploadIcon} />
          </div>
          <div>
            <h2>Upload New Track</h2>
          </div>
        </>
      ) : (
        <>
          <div className={Styles.upgradeIcon}>
            <FileDown size={32} className={Styles.uploadIcon} />
          </div>
          <h3 className={Styles.upgradeTitle}>Ready to Start Uploading?</h3>
          <p className={Styles.upgradeText}>
            Upgrade to start uploading your tracks and get personalized feedback
          </p>
          <button className={Styles.upgradeButton}>
            Upgrade
          </button>
        </>
      )}
    </SMLCard>
  );
};

const VideoCard = () => {
  const playerWrapperRef = React.useRef(null);

  React.useEffect(() => {
    const resizePlayerWrapper = () => {
      if (playerWrapperRef.current) {
        const width = playerWrapperRef.current.offsetWidth;
        playerWrapperRef.current.style.height = `${width * (9 / 16)}px`;
      }
    };

    resizePlayerWrapper();
    window.addEventListener("resize", resizePlayerWrapper);

    return () => {
      window.removeEventListener("resize", resizePlayerWrapper);
    };
  }, []);

  return (
    <SMLCard className={Styles.videoContainer}>
      <div ref={playerWrapperRef} className={Styles.playerWrapper}>
        <ReactPlayer
          playing={true}
          url={video}
          light={thumnail}
          className={Styles.reactPlayer}
          width="100%"
          height="100%"
          controls
        />
      </div>
      <div className={Styles.videoInfo}>
        <h6>Introduction</h6>
        <h4>SML in a Nutshell</h4>
      </div>
    </SMLCard>
  );
};

const RecentActivity = () => {
  return (
    <SMLCard className={Styles.recentActivityContainer}>
      <div>
        <h6>Recent Activity</h6>
        <h2>You Submitted a Task For Review.</h2>
      </div>
      <div>
        <div>
          <h6>Track</h6>
          <h4>Name of Track</h4>
        </div>
        <div>
          <h6>Status</h6>
          <h4>
            Under Review <img src={UnderReviewIcon} alt="under review"></img>
          </h4>
        </div>
      </div>
    </SMLCard>
  );
};

const StatusCard = ({ imageSrc, label, stats, onClick }) => {
  return (
    <SMLCard className={Styles.statusCard} onClick={onClick}>
      <img src={imageSrc} alt="statsImage" />
      <h2>{label}</h2>
      <h3>{stats}</h3>
    </SMLCard>
  );
};

const TracksCard = ({ secondaryLabel, tracks, status, loadingTracks }) => {
  const navigate = useNavigate();
  const handleTrackClick = (trackId, status) => {
    if (status !== "final_feedback_submitted") {
      navigate(`/TrackDetails/${trackId}`, {
        state: { fromUserPortal: true, status, fromDashboard: true },
      });
    } else {
      navigate(`/TrackDetailsFinal/${trackId}`, {
        state: { status, fromDashboard: true },
      });
    }
  };
  return (
    <SMLCard2 className={Styles.tracksContainer}>
      {Array.isArray(tracks) && tracks.length > 0 ? (
        <>
          {tracks.map((item, index) => {
            const isOverflowing = (element) => {
              return element.offsetWidth < element.scrollWidth;
            };

            return (
              <div
                className={Styles.trackRow}
                key={item?.key || index}
                onClick={() => {
                  handleTrackClick(item._id, item.status.code);
                }}
              >
                <img src={TrackIcon} alt="trackIcon" />
                <h2
                  ref={(el) => {
                    if (el && isOverflowing(el)) {
                      el.setAttribute("data-tooltip-content", item.title);
                    }
                  }}
                >
                  {item?.title.length > 40 ? (
                    <>
                      {item?.title.substring(0, 40)}...
                      <Tooltip id="trackTitle-details-final" />
                    </>
                  ) : (
                    item?.title
                  )}
                </h2>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {!loadingTracks && (
            <h5 className={Styles.tracksEmptyPlaceholder}>
              You do not have any {status} tracks.
            </h5>
          )}
        </>
      )}

      <h6 className={Styles.tracksSecondaryText}>{secondaryLabel}</h6>
    </SMLCard2>
  );
};


// Original Tracks Component
const TrackSection = ({
  stats,
  submittedTracks,
  inReviewTracks,
  feedbackReceievedTracks,
  submittedTracksLoading,
  inReviewTracksLoading,
  feedbackReceievedTracksLoading,
  handleUploadedTrackClick,
  handleInReviewClick,
  handleFeedbackSubmittedClick,
}) => {
  return (
    <>
      <StatusCard
        onClick={handleUploadedTrackClick}
        imageSrc={UploadedTracksIcon}
        label="Uploaded Tracks"
        stats={stats?.submitted}
      />
      {submittedTracks && (
        <TracksCard
          tracks={submittedTracks}
          status="submitted"
          loadingTracks={submittedTracksLoading}
        />
      )}
      <StatusCard
        onClick={handleInReviewClick}
        imageSrc={ReviewTrackIcon}
        label="Tracks Under Review !"
        stats={stats?.under_review}
      />
      <TracksCard
        tracks={inReviewTracks}
        status="under review"
        loadingTracks={inReviewTracksLoading}
      />
      <StatusCard
        onClick={handleFeedbackSubmittedClick}
        imageSrc={FeedbackIcon}
        label="Feedback Received"
        stats={stats?.feedback_received}
      />
      <TracksCard
        tracks={feedbackReceievedTracks}
        status="feedback received"
        loadingTracks={feedbackReceievedTracksLoading}
      />
    </>
  );
};
