import {
  CANCEL_SUB_REQUEST,
  CREATE_SUB_REQUEST,
  GET_CLIENT_SECRET_REQUEST,
  GET_PB_KEY_FAILURE,
  GET_PB_KEY_LOADING,
  GET_PB_KEY_REQUEST,
  GET_PB_KEY_SUCCESS,
  GET_PLANS_REQUEST,
  GET_PRO_ANALYSIS_FAILURE,
  GET_PRO_ANALYSIS_LOADING,
  GET_PRO_ANALYSIS_REQUEST,
  GET_PRO_ANALYSIS_SUCCESS,
  GET_SUB_BY_PROFILE_REQUEST,
  GET_SUB_DETAILS_REQUEST,
  SUB_PRO_ANALYSIS_FAILURE,
  SUB_PRO_ANALYSIS_LOADING,
  SUB_PRO_ANALYSIS_REQUEST,
  SUB_PRO_ANALYSIS_SUCCESS,
  UPDATE_PAYMENT_METHOD_REQUEST,
} from "./actionTypes";

export const getPbKeyRequest = () => {
  return {
    type: GET_PB_KEY_REQUEST,
  };
};

export const getPlansRequest = () => {
  return {
    type: GET_PLANS_REQUEST,
  };
};

export const getClientSecret = ({ amount, currency }) => {
  return {
    type: GET_CLIENT_SECRET_REQUEST,
    payload: { amount, currency },
  };
};
export const createSubReqeuest = ({ id, email, priceId, methodId }) => {
  return {
    type: CREATE_SUB_REQUEST,
    payload: { id, email, priceId, methodId },
  };
};

export const setSelectedPlan = (plan) => ({
  type: "SET_SELECTED_PLAN",
  payload: plan,
});

export const cancelSubRequest = (id, reason, desc) => {
  return {
    type: CANCEL_SUB_REQUEST,
    payload: { id, reason, desc },
  };
};

export const getSubByProfileRequest = ({ profileId }) => {
  return {
    type: GET_SUB_BY_PROFILE_REQUEST,
    payload: { profileId },
  };
};

export const getSubDetailsRequest = ({ subscriptionId }) => {
  return {
    type: GET_SUB_DETAILS_REQUEST,
    payload: { subscriptionId },
  };
};

export const updatePaymentMethodRequest = ({
  subscriptionId,
  paymentMethodId,
}) => {
  return {
    type: UPDATE_PAYMENT_METHOD_REQUEST,
    payload: { subscriptionId, paymentMethodId },
  };
};

export const subProAnalysisRequest = ({
  trackId,
  priceId,
  paymentMethodId,
  serviceType,
}) => {
  return {
    type: SUB_PRO_ANALYSIS_REQUEST,
    payload: { trackId, priceId, paymentMethodId, serviceType },
  };
};

export const getProAnalysisRequest = ({ trackId }) => {
  return {
    type: GET_PRO_ANALYSIS_REQUEST,
    payload: { trackId },
  };
};
