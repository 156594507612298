import React, { useState, useRef, useEffect } from "react";
import { Play, FileDown, Clock, X, Copy, Check } from "lucide-react";
import SMLCard from "../common/styled-components/SMLCard";
import styles from "./ResourceSection.module.css";

const ResourceSection = () => {
  const [activeVideo, setActiveVideo] = useState(null);
  const videoRef = useRef(null);
  const thumbnailRefs = useRef([]);
  const [videoDurations, setVideoDurations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnailsLoaded, setThumbnailsLoaded] = useState({});
  const [copySuccess, setCopySuccess] = useState(null);

  const videos = [
    {
      title: "Edit Point Defined",
      thumbnail:
        "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/thumbnails/Edit+Points.png",
      url: "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/SML+Edit+Points+Defined.mp4",
    },
    {
      title: "Stop Downs",
      thumbnail:
        "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/thumbnails/Stop+Downs.png",
      url: "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/SML+Stop+Downs.mp4",
    },
    {
      title: "Assets and Deliverables",
      thumbnail:
        "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/thumbnails/Stems+%26+Assets.png",
      url: "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/SML+Assets+and+Deliverables.mp4",
    },
    {
      title: "Anatomy of a Song",
      thumbnail:
        "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/thumbnails/song+ownership.png",
      url: "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/Anatomy+of+a+song.mp4",
    },
    {
      title: "Email Template",
      thumbnail:
        "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/thumbnails/email+blast+template.png",
      url: "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/email+template.mov",
    },
  ];

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    // Preconnect to CDN
    const link = document.createElement("link");
    link.rel = "preconnect";
    link.href = "https://sml-dev.s3.us-west-2.amazonaws.com";
    document.head.appendChild(link);

    // Calculate duration for each video
    videos.forEach((video, index) => {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";

      videoElement.onloadedmetadata = function () {
        const duration = videoElement.duration;
        setVideoDurations((prev) => ({
          ...prev,
          [index]: formatDuration(duration),
        }));
      };

      videoElement.src = video.url;
    });

    // Add event listeners to stop videos after playing once
    thumbnailRefs.current.forEach((videoRef) => {
      if (videoRef) {
        videoRef.addEventListener("ended", () => {
          videoRef.currentTime = videoRef.duration - 0.1; // Stop at last frame
        });
      }
    });

    // Cleanup event listeners
    return () => {
      thumbnailRefs.current.forEach((videoRef) => {
        if (videoRef) {
          videoRef.removeEventListener("ended", () => {});
        }
      });
    };
  }, []);

  const handleVideoClick = (video) => {
    setActiveVideo(video);
  };

  const closeVideo = () => {
    setActiveVideo(null);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const templates = [
    {
      title: "Music Submission Template",
      type: "Email Template",
      description: "Professional email template for music submissions",
      content: `Hi [Supervisor's First Name],

I'm such a fan of the show and I loved how you used [specific song/artist] in [specific scene]. It made the scene when [short scene reference] capture and enhance that moment so well. 

I have a [genre] track that could be perfect for [show name]'s [specific type of scene]. It's called "[Song Title]" by [Artist Name] and has a similar emotional impact to [reference track/artist they've licensed before].

We're excited about [Artist name]. She's a really interesting artist with a recent write up in [list any publication write ups]. I'd love it if you had a listen.

[downloadable link]

Would love your thoughts on this for [show name] or any other projects you're currently working on. We can clear the song one-stop! Stems are available.

Also, please feel free to add us to your email list for any briefs you send out. We have songs in many styles and moods.

Thanks for taking the time.

Best,
[Your Name] 
[Your Company and/or website] 
[Phone number]`,
    },
  ];

  const handleCopyTemplate = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(null), 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const handleDownloadTemplate = (url, title) => {
    window.open(url, "_blank");
  };
  
  // const handleDownloadTemplate = (content, title) => {
  //   const element = document.createElement("a");
  //   const file = new Blob([content], { type: "text/plain" });
  //   element.href = URL.createObjectURL(file);
  //   element.download = `${title.toLowerCase().replace(/\s+/g, "-")}.txt`;
  //   document.body.appendChild(element);
  //   element.click();
  //   document.body.removeChild(element);
  // };

  return (
    <>
      <SMLCard className={styles.resourceCard}>
        <h2 className={styles.sectionTitle}>Sync Insider Essentials</h2>
        <div className={styles.videoGrid}>
          {videos.map((video, index) => (
            <div
              key={index}
              className={styles.videoCard}
              onClick={() => handleVideoClick(video)}
            >
              <div className={styles.thumbnailContainer}>
                <img
                  className={styles.thumbnail}
                  ref={(el) => (thumbnailRefs.current[index] = el)}
                  src={video.thumbnail} // Make sure this points to the PNG image URL
                  alt="Thumbnail"
                />

                {/* <video 
                  className={styles.thumbnail}
                  ref={el => thumbnailRefs.current[index] = el}
                  preload="metadata"
                  muted
                  playsInline
                  autoPlay
                >
                  <source src={video.thumbnail} type="video/mp4" />
                </video> */}
                <div className={styles.videoOverlay}>
                  <div className={styles.videoInfo}>
                    <h4 className={styles.videoTitle}>{video.title}</h4>
                    <div className={styles.videoMeta}>
                      <span className={styles.duration}>
                        <Clock size={12} className={styles.durationIcon} />
                        {videoDurations[index] || "0:00"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </SMLCard>

      {/* Video Player Overlay */}
      {activeVideo && (
        <div className={styles.videoPlayerOverlay} onClick={closeVideo}>
          <div
            className={styles.videoPlayerContent}
            onClick={(e) => e.stopPropagation()}
          >
            <button className={styles.closeButton} onClick={closeVideo}>
              <X size={24} />
            </button>
            <video
              ref={videoRef}
              className={styles.videoPlayer}
              controls
              autoPlay
              preload="auto"
            >
              <source src={activeVideo.url} type="video/mp4" />
            </video>
          </div>
        </div>
      )}

      <SMLCard className={styles.resourceCard}>
        <h2 className={styles.sectionTitle}>Templates & Resources</h2>
        <div className={styles.templateList}>
          {templates.map((template, index) => (
            <div key={index} className={styles.templateRow}>
              <div className={styles.templateIcon}>
                <FileDown size={24} />
              </div>
              <div className={styles.templateInfo}>
                <h3 className={styles.templateTitle}>{template.title}</h3>
                <p className={styles.templateDescription}>
                  {template.description}
                </p>
                <span className={styles.templateMeta}>{template.type}</span>
              </div>
              <div className={styles.templateActions}>
                <button
                  className={styles.actionButton}
                  onClick={() => handleCopyTemplate(template.content)}
                  title="Copy to clipboard"
                >
                  {copySuccess ? <Check size={20} /> : <Copy size={20} />}
                </button>
                <button
  className={styles.actionButton}
  onClick={() =>
    handleDownloadTemplate(
      "https://sml-dev.s3.us-west-2.amazonaws.com/resources/videos/Templates/SML+Personalized+email+template.pdf",
      "SML Personalized email template.pdf"
    )
  }
  title="Download template"
>
  <FileDown size={20} />
</button>

              </div>
            </div>
          ))}
        </div>
      </SMLCard>
    </>
  );
};

export default ResourceSection;
