import React, { useEffect, useState } from "react";
import "./PaymentPopup.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubReqeuest,
  getClientSecret,
  getPlansRequest,
  setSelectedPlan,
  getSubByProfileRequest,
  getSubDetailsRequest,
  updatePaymentMethodRequest,
} from "../../../redux/actions/paymentActions";
import {
  signupRequest,
  socialSignupRequest,
} from "../../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import ProgressSteps from "../../../pages/SignUpPage/ProgressSteps/ProgressSteps";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import Backbutton from "../../Shared/BackButton/BackButton";
import SMLButton from "../../Shared/SMLButton/SMLButton";
import plans from "../../../pages/SignUpPage/ChoosePlan/ChoosePlan.json";
import features from "../../../pages/SignUpPage/ChoosePlan/features.json";
import checkBox from "../../../assets/Images/checkboxIcon.svg";
import { APPCONFIG } from "../../../config/APPConfig";
import PricingPlanCard from "./PricingPlanCard";
import CheckoutForm from "./CheckoutForm";
import { ServerIcon } from "lucide-react";

const PaymentPopup = ({
  selectedPlan,
  contactInfo,
  aboutYouData,
  nextClick,
  closePopup,
  backClick,
  forUpgrade,
  isYearly,
  specialService: serviceType,
}) => {
  const specialPlan = features[0];
  const specialPlan1 = features[1];
  const [paymentTriggered, setPaymentTriggered] = useState(false);
  const [existingSubscription, setExistingSubscription] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const subByProfile = useSelector(
    (state) => state.payment?.getSubByProfileReducer?.data
  );
  const subDetails = useSelector(
    (state) => state.payment?.getSubDetailsReducer?.data
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { artists } = useSelector((state) => state.sml.allArtistFetched);
  const { artistSignedUp } = useSelector((state) => state.auth);

  const handlePayment = () => {
    setPaymentTriggered(false);
  };

  // First useEffect to trigger the API call
  useEffect(() => {
    const profileId = forUpgrade ? artists?._id : artistSignedUp?.profile?._id;
    if (!profileId) return;

    dispatch(getSubByProfileRequest({ profileId }));
  }, [artists?._id, artistSignedUp?.profile?._id]);

  // Second useEffect to handle subscription data
  useEffect(() => {
    if (subByProfile && subByProfile.length > 0) {
      const subscription = subByProfile[0];
      setExistingSubscription(subscription);

      // Add this dispatch to get subscription details
      if (subscription.stripeSubscriptionId) {
        dispatch(
          getSubDetailsRequest({
            subscriptionId: subscription.stripeSubscriptionId,
          })
        );
      }
    }
  }, [subByProfile]);

  // Third useEffect to handle payment method data
  useEffect(() => {
    if (subDetails && Array.isArray(subDetails) && subDetails.length > 0) {
      setPaymentMethods(subDetails); // Set directly since it's already an array of payment methods
    }
  }, [subDetails]);

  const auth = useSelector((state) => state.auth);
  const { artistSocialSignedIn } = useSelector((state) => state.auth);
  const isLoadingSub = useSelector(
    (state) => state.payment.createSubReducer?.isLoading
  );

  const subCreated = useSelector(
    (state) => state.payment?.createSubReducer?.result
  );
  const specialSubCreated = useSelector(
    (state) => state.payment?.subProAnalysisReducer?.data
  );

  useEffect(() => {
    if (
      (subCreated && subCreated?.length !== 0) ||
      (specialSubCreated && specialSubCreated?.length !== 0)
    ) {
      if (artistSignedUp?.is_new_user === false) {
        navigate("/Dashboard");
      } else {
        nextClick();
      }
    }
    console.log(specialSubCreated, "specialSubCreated1");
  }, [subCreated, specialSubCreated]);

  const findMatchingPlan = (selectedPlan) => {
    const priceId = selectedPlan.id; // This is the plan's ID that was selected
    // Make sure plans are available
    if (!plans || plans.length === 0) {
      return undefined;
    }

    // Log whether you're expecting yearly or monthly ID

    // Check if we're correctly finding the plan
    const matchingPlan = plans.find((plan) => {
      const planIdToCompare = isYearly ? plan.YearlyId : plan.monthlyId;
      return planIdToCompare === priceId;
    });

    if (!matchingPlan) {
      console.log("No matching plan found for the given ID.");
    } else {
      console.log("Matching Plan Found:", matchingPlan);
    }

    return matchingPlan;
  };

  // const findMatchingPlan = (plan) => {
  //   const priceId = selectedPlan.id
  //isYearly ? plan.YearlyId : plan.monthlyId;

  //   return plans.find((p1) => p1.id === priceId);
  // };

  const [planData, setPlanData] = useState(null);

  useEffect(() => {
    if (selectedPlan.name === "Track Analysis") {
      setPlanData(specialPlan);
    } else if (selectedPlan.name === "Video-Consultation Services") {
      setPlanData(specialPlan1);
    } else {
      const matchingPlan = findMatchingPlan(selectedPlan);
      console.log("Matching Plan:", matchingPlan); // Log the matching plan before setting it
      setPlanData(matchingPlan); // Set the plan data once confirmed
    }
  }, [selectedPlan]);

  return (
    <div id="checkout">
      <div className="signUp-progress-steps">
        {forUpgrade ? (
          <ProgressSteps step="1" upgrade="true" serviceType = {serviceType} />
        ) : (
          <ProgressSteps step="3" />
        )}
      </div>
      <div
        className="signUp-close-div"
        onClick={() => {
          closePopup();
          dispatch(getPlansRequest());
        }}
      >
        <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
      </div>

      <div className="d-flex justify-content-center align-items-center payment-popup">
        <div className="left-payment">
          {planData && (
            <PricingPlanCard planData={planData} isYearly={isYearly} />
          )}
        </div>
        <div className="line-payment"></div>
        <div className="right-payment">
          {isLoadingProfile || isLoadingDetails || isLoadingSub ? (
            <div className="spinner-overlay">
              <Bars
                height="80"
                width="80"
                color="#23f0c7"
                ariaLabel="bars-loading"
                visible={true}
              />
            </div>
          ) : (
            <CheckoutForm
              selectedPlan={selectedPlan}
              // contactInfo={contactInfo}
              // aboutYouData={aboutYouData}
              isPaymentTriggered={paymentTriggered}
              serviceType={serviceType}
              handlePayment={() => setPaymentTriggered(false)}
              nextClick={nextClick}
              upgrade={forUpgrade}
              existingSubscription={existingSubscription}
              paymentMethods={paymentMethods}
            />
          )}
        </div>
      </div>

      <div className="signUp-footer">
        <div onClick={backClick}>
          <Backbutton />
        </div>
        <div
          className="continue-btns"
          onClick={() => setPaymentTriggered(true)}
        >
          <SMLButton
            handleClickEvent={() => {}}
            style="SMLButton2 SMLButton-Orange"
            value={forUpgrade ? "Confirm Payment" : "Subscribe"}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentPopup;
