import { call, put, takeLatest, all } from "redux-saga/effects";
import { getNotificationFailure, getNotificationSuccess } from "../actions";
import {
  GET_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_REQUEST,
} from "../actions/actionTypes";
import { getNotifsApi, readNotifApi } from "../../api/notifs/notifApi";
import { getNotificationResponseSuccess } from "../actions/notificationActions";

function* notificationSaga(action) {
  try {
    const response = yield call(getNotifsApi, action.payload);
    const notifs = response.data; // Ensure this is an array
    if (!Array.isArray(notifs)) {
      throw new Error("Notifications data is not an array");
    }
    yield put(getNotificationSuccess({ notifs }));
    yield put(getNotificationResponseSuccess({ response }));
  } catch (error) {
    yield put(getNotificationFailure({ error: error.message })); // Call as a function
  }
}

function* updateNotificationSaga(action) {
  try {
    const update = yield call(readNotifApi);
    // yield
  } catch (error) {
    yield put({ type: UPDATE_NOTIFICATION_FAILURE });
  }
}

export default function* getNotificationSaga() {
  yield all([takeLatest(GET_NOTIFICATION_REQUEST, notificationSaga)]);
  yield all([takeLatest(UPDATE_NOTIFICATION_REQUEST, updateNotificationSaga)]);
}
