import React, { useEffect, useRef, useState } from "react";
import SMLCard from "../styled-components/SMLCard";
import SMLTextWithLabel from "../styled-components/SMLTextWithLabel";
import Styles from "./TracksViewStyles.module.css";
import UnderReviewIcon from "../../../assets/Icons/under-review.svg";
import CertifiedIcon from "../../../assets/Icons/certified.svg";
import FeedbackRecievedIcon from "../../../assets/Icons/feedback-recieved.svg";
import NotSubmittedIcon from "../../../assets/Icons/not-submitted.svg";
import DefaultTrackImg from "../../../assets/Images/TrackDefaultImg.svg";
import PlayIcon from "../../../assets/Icons/play.svg";
import PauseIcon from "../../../assets/Icons/pause.svg";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ArrowUpRight } from "lucide-react";

export default function TrackCard({ track, onPlay, isStopped }) {
  const trackLengthInMilliSeconds = 10000;
  const [isPlaying, setIsPlaying] = useState(false);
  const [elapsedSeconds, setElaspsedSeconds] = useState(0);
  const musicTimeoutRef = useRef(null);
  const musicStoppedFromParent = useRef(false);
  // const audioRef = useRef(new Audio(track.file_url));
  const navigate = useNavigate();
  const trackId = track._id;
  const status = track.status.code;

  const handleCardClick = (trackId) => {
    if (status !== "final_feedback_submitted") {
      navigate(`/TrackDetails/${trackId}`, {
        state: { status },
      });
    } else {
      navigate(`/TrackDetailsFinal/${trackId}`, {
        state: { status },
      });
    }
  };

  const handleOriginalClick = (e) => {
    e.stopPropagation();
    navigate(`/TrackDetails/${track.original_track_id}`, {
      state: { status: "submitted" }, //final_feedback_submitted
    });
  };

  return (
    <SMLCard
      className={Styles.trackCard}
      onClick={() => {
        handleCardClick(trackId);
      }}
    >
      <div className={Styles.trackImage}>
        <img
          src={DefaultTrackImg}
          alt="track imge"
          height={"100%"}
          width={"100%"}
        />
        {isPlaying ? (
          <img
            src={PauseIcon}
            alt="play"
            className={Styles.playPauseControllbutton}
          />
        ) : (
          <img
            src={PlayIcon}
            alt="pause"
            className={Styles.playPauseControllbutton}
          />
        )}
      </div>
      <div className={Styles.trackNameAndLabelContaienr}>
        <h3
          data-tooltip-id="trackTitle-player"
          data-tooltip-content={track?.title}
          className={Styles.trackTitle}
        >
          {track?.title}
        </h3>
        <Tooltip id="trackTitle-player" multiline="true" place="top" />
        <div className={Styles.metadataContainer}>
          <SMLTextWithLabel label={"Genre"} text={track.genre} />
          {track.original_track_id && (
            <div className={Styles.revisionContainer}>
              <SMLTextWithLabel
                label={"Revision"}
                text={
                  <div className={Styles.revisionText}>
                    {track.revision_number || "-"}
                    <button
                      onClick={handleOriginalClick}
                      className={Styles.viewOriginalLink}
                    >
                      <ArrowUpRight size={14} />
                    </button>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className={Styles.trackLengthAndStatusContainer}>
        <h4>{track.length}</h4>
        <SMLTextWithLabel
          label={"Status"}
          text={track.status?.label}
          textImg={getStatusImage(track.status?.label)}
        />
      </div>
    </SMLCard>
  );
}

const getStatusImage = (status) => {
  switch (status) {
    case "Submitted":
      return UnderReviewIcon;
    case "In Review":
      return UnderReviewIcon;
    case "Certified":
      return CertifiedIcon;
    case "Final Feedback Submitted":
      return FeedbackRecievedIcon;
    case "Not Submitted":
      return NotSubmittedIcon;
    default:
      return undefined;
  }
};
