import { call, put, takeLatest } from "redux-saga/effects";
import {
  CANCEL_SUB_FAILURE,
  CANCEL_SUB_LOADING,
  CANCEL_SUB_REQUEST,
  CANCEL_SUB_SUCCESS,
  CREATE_SUB_FAILURE,
  CREATE_SUB_LOADING,
  CREATE_SUB_REQUEST,
  CREATE_SUB_SUCCESS,
  GET_CLIENT_SECRET_LOADING,
  GET_CLIENT_SECRET_REQUEST,
  GET_CLIENT_SECRET_SUCCESS,
  GET_PB_KEY_FAILURE,
  GET_PB_KEY_LOADING,
  GET_PB_KEY_REQUEST,
  GET_PB_KEY_SUCCESS,
  GET_PLANS_FAILURE,
  GET_PLANS_LOADING,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_SUB_BY_PROFILE_FAILURE,
  GET_SUB_BY_PROFILE_LOADING,
  GET_SUB_BY_PROFILE_REQUEST,
  GET_SUB_BY_PROFILE_SUCCESS,
  GET_SUB_DETAILS_FAILURE,
  GET_SUB_DETAILS_LOADING,
  GET_SUB_DETAILS_REQUEST,
  GET_SUB_DETAILS_SUCCESS,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_LOADING,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILURE,
  GET_PRO_ANALYSIS_REQUEST,
  SUB_PRO_ANALYSIS_REQUEST,
  SUB_PRO_ANALYSIS_FAILURE,
  SUB_PRO_ANALYSIS_SUCCESS,
  SUB_PRO_ANALYSIS_LOADING,
  GET_PRO_ANALYSIS_FAILURE,
  GET_PRO_ANALYSIS_SUCCESS,
  GET_PRO_ANALYSIS_LOADING,
} from "../actions/actionTypes";
import { getPbKeyApi } from "../../api/payment/getPbKey";
import { getPlansApi } from "../../api/payment/getPlans";
import { getClientSecretApi } from "../../api/payment/getClientSecret";
import { createSubApi } from "../../api/payment/createSub";
import { cancelSubApi } from "../../api/payment/cancelSub";
import { getSubByProfileApi } from "../../api/payment/getSubByProfile";
import { getSubDetailsApi } from "../../api/payment/getSubDetails";
import { updatePaymentMethodApi } from "../../api/payment/updatePaymentMethod";
import { getProAnalysisApi } from "../../api/payment/getProAnalysisApi";
import { subProAnalysisApi } from "../../api/payment/subProAnalysisApi";
function* getPbKey() {
  try {
    yield put({ type: GET_PB_KEY_LOADING });
    const key = yield call(getPbKeyApi);
    yield put({ type: GET_PB_KEY_SUCCESS, key });
  } catch (error) {
    yield put({ type: GET_PB_KEY_FAILURE, error });
  }
}
function* getPlans() {
  try {
    yield put({ type: GET_PLANS_LOADING });
    const plans = yield call(getPlansApi);
    yield put({ type: GET_PLANS_SUCCESS, plans });
  } catch (error) {
    yield put({ type: GET_PLANS_FAILURE, error });
  }
}
function* getClientSecret(action) {
  try {
    yield put({ type: GET_CLIENT_SECRET_LOADING });
    const secret = yield call(getClientSecretApi, action.payload);
    yield put({ type: GET_CLIENT_SECRET_SUCCESS, secret: secret.clientSecret });
  } catch (error) {
    yield put({ type: GET_PB_KEY_FAILURE, error });
  }
}
function* createSub(action) {
  try {
    yield put({ type: CREATE_SUB_LOADING });
    const result = yield call(createSubApi, action.payload);
    yield put({ type: CREATE_SUB_SUCCESS, result });
  } catch (error) {
    yield put({ type: CREATE_SUB_FAILURE, error });
  }
}
function* cancelSub(action) {
  try {
    yield put({ type: CANCEL_SUB_LOADING });
    const data = action.payload;
    const cancelled = yield call(cancelSubApi, data);
    yield put({ type: CANCEL_SUB_SUCCESS, cancelled });
  } catch (error) {
    yield put({ type: CANCEL_SUB_FAILURE, error });
  }
}

function* getProAnalysis(action) {
  try {
    yield put({ type: GET_PRO_ANALYSIS_LOADING });
    const data = yield call(getProAnalysisApi, action.payload);
    yield put({ type: GET_PRO_ANALYSIS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PRO_ANALYSIS_FAILURE, error });
  }
}

function* subProAnalysis(action) {
  try {
    yield put({ type: SUB_PRO_ANALYSIS_LOADING });
    const data = yield call(subProAnalysisApi, action.payload);
    yield put({ type: SUB_PRO_ANALYSIS_SUCCESS, data });
  } catch (error) {
    yield put({ type: SUB_PRO_ANALYSIS_FAILURE, error });
  }
}

function* paymentSaga() {
  yield takeLatest(GET_PB_KEY_REQUEST, getPbKey);
  yield takeLatest(GET_PLANS_REQUEST, getPlans);
  yield takeLatest(GET_CLIENT_SECRET_REQUEST, getClientSecret);
  yield takeLatest(CREATE_SUB_REQUEST, createSub);
  yield takeLatest(CANCEL_SUB_REQUEST, cancelSub);
  yield takeLatest(GET_SUB_BY_PROFILE_REQUEST, getSubByProfile);
  yield takeLatest(GET_SUB_DETAILS_REQUEST, getSubDetails);
  yield takeLatest(UPDATE_PAYMENT_METHOD_REQUEST, updatePaymentMethod);
  yield takeLatest(GET_PRO_ANALYSIS_REQUEST, getProAnalysis);
  yield takeLatest(SUB_PRO_ANALYSIS_REQUEST, subProAnalysis);
}

function* getSubByProfile(action) {
  try {
    yield put({ type: GET_SUB_BY_PROFILE_LOADING });
    const data = yield call(getSubByProfileApi, action.payload);
    yield put({ type: GET_SUB_BY_PROFILE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SUB_BY_PROFILE_FAILURE, error });
  }
}

function* getSubDetails(action) {
  try {
    yield put({ type: GET_SUB_DETAILS_LOADING });
    const data = yield call(getSubDetailsApi, action.payload);
    yield put({ type: GET_SUB_DETAILS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SUB_DETAILS_FAILURE, error });
  }
}

function* updatePaymentMethod(action) {
  try {
    yield put({ type: UPDATE_PAYMENT_METHOD_LOADING });
    const data = yield call(updatePaymentMethodApi, action.payload);
    yield put({ type: UPDATE_PAYMENT_METHOD_SUCCESS, data });
    
    // Refresh payment methods after update
    if (action.payload.subscriptionId) {
      yield put(getSubDetails({ subscriptionId: action.payload.subscriptionId }));
    }
  } catch (error) {
    yield put({ type: UPDATE_PAYMENT_METHOD_FAILURE, error });
  }
}

export default paymentSaga;
