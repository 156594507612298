import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  loginRequest,
  socialLoginRequest,
} from "../../redux/actions/authActions";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "../../styles/Fonts.scss";
import "./LoginPage.css";
import closeIcon from "../../../src/assets/Images/closeIcon.svg";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLButtonWithIcon from "../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon";
import SMLLinkButton from "../../components/Shared/SMLLinkButton/SMLLinkButton";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import SignUpPage from "../SignUpPage/SignUpPage";
import { BiQuestionMark } from "react-icons/bi";
import { Bars } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";
import ForgetPassword from "../../components/common/forget-password/ForgetPassword";
import {
  RESET_DELETE_PROFILE,
  RESET_FORGET_PASSWORD,
} from "../../redux/actions/actionTypes";
import { websocketConnect } from "../../redux/actions/websocketActions";
import { APPCONFIG } from "../../config/APPConfig";
import FacebookLoginButton from "../../components/common/FacebookLoginButton/FacebookLoginButton";
import { confirmMailRequest } from "../../redux/actions";
import { CheckBoxRounded } from "@mui/icons-material";
import { Check, X } from "lucide-react";

const Login = ({ title, onClose, confirmMail }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signUpShow, setSignUpShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [showForgetPass, setShowForgetPass] = useState(false);
  const [fbError, setFbError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile_type = "artist";
  const { user, loading, error } = useSelector((state) => state.auth);

  const {
    isLoading: confirmMailLoading,
    isError: isConfirmMailError,
    error: confirmMailError,
    confirmed: confirmedMail,
  } = useSelector((state) => state.confirmMail.confirmMail);

  // useEffect(() => {}, [
  const query = new URLSearchParams(useLocation().search);
  const username = query.get("email");
  const code = query.get("code");

  const decodedUsername = decodeURIComponent(username);
  // ])

  useEffect(() => {
    if (decodedUsername && code) {
      dispatch(confirmMailRequest({ mail: decodedUsername, code }));
    }
  }, [dispatch, decodedUsername, code]);

  useEffect(() => {
    dispatch({ type: RESET_DELETE_PROFILE });
  }, []);

  useEffect(() => {
    if (user && user.profile_type === "artist") {
      localStorage.setItem("username", user.name);
      localStorage.setItem("emails", user.email);
      localStorage.setItem("profile_type", user.profile_type);
      navigate("/dashboard", { state: { username: user.name } });
      dispatch(websocketConnect());
    }
  }, [user, navigate]);

  const handleLoginContainerClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (error) setLoginError(true);
  }, [error]);

  useEffect(() => {
    setLoginError(false);
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleLogin = (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    } else {
      setEmailError("");
    }

    if (!password.trim()) {
      setPasswordError("Password is required");
      return;
    } else {
      setPasswordError("");
    }

    dispatch(loginRequest(email, password, profile_type));
  };

  const handleSignUpClick = (e) => {
    setSignUpShow(true);
  };
  const handleForgetPassClick = (e) => {
    e.preventDefault();
    dispatch({ type: RESET_FORGET_PASSWORD });
    setShowForgetPass(true);
  };

  const responseFacebook = (response) => {
    const profile = {
      name: response.name,
      email: response.email,
    };
    localStorage.setItem("username", profile.name);
    localStorage.setItem("emails", profile.email);
    localStorage.setItem("isFacebookLoggedIn", "true");
    navigate("/dashboard");
  };

  const handleGoogleLogin = (credentialResponse) => {
    dispatch(
      socialLoginRequest(credentialResponse.credential, "google", "artist")
    );
  };

  const handleFacebookSuccess = ({ accessToken, userInfo }) => {
    dispatch(socialLoginRequest(accessToken, "facebook", "artist"));

    localStorage.setItem("username", userInfo.name);
    localStorage.setItem("emails", userInfo.email);
    localStorage.setItem("isFacebookLoggedIn", "true");
  };

  const handleFacebookError = (error) => {
    console.error("Facebook login error:", error);
    setFbError(error);
  };

  return showForgetPass ? (
    <ForgetPassword onClose={onClose} />
  ) : signUpShow ? (
    <SignUpPage onClose={onClose} />
  ) : (
    <Modal show={true} onHide={onClose} centered className="custom-login-modal">
      <div className="login-containers" onClick={handleLoginContainerClick}>
        <form
          className="login-card"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin(e);
            }
          }}
        >
          {(confirmMailLoading || loading) && (
            <div className="spinner-overlay">
              <Bars
                height="80"
                width="80"
                color="#23f0c7"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}

          <div className="Login-close-div" onClick={onClose}>
            <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
          </div>

          <div className="login-main">
            <div className="login-label-div">
              <SMLLabel value={"Log In"} style="Mainheader" />
            </div>
            {confirmedMail && (
              <div className="login-confirm-label-div">
                <span
                  style={{
                    backgroundColor: "#03c849",
                    padding: "0.1rem",
                    color: "white",
                    borderRadius: "10px",
                    marginRight: "2px",
                  }}
                >
                  <Check />
                </span>
                Email confirmed successfully! Please login to continue
              </div>
            )}

            {isConfirmMailError &&
              (confirmMailError === "User is already confirmed." ? (
                <div className="login-confirm-label-div">
                  <span
                    style={{
                      backgroundColor: "#03c849",
                      padding: "0.1rem",
                      color: "white",
                      borderRadius: "10px",
                      marginRight: "1rem",
                    }}
                  >
                    <Check style={{ fontSize: "2px" }} />
                  </span>
                  {confirmMailError} Please login to continue
                </div>
              ) : (
                <div className="login-not-confirm-label-div">
                  <span
                    style={{
                      backgroundColor: "#c80303",
                      padding: "0.1rem",
                      color: "white",
                      borderRadius: "10px",
                      marginRight: "1rem",
                    }}
                  >
                    <X />
                  </span>
                  {confirmMailError}
                </div>
              ))}
            <div className="Login-content" onSubmit={handleLogin}>
              <div className="login-controls">
                <div className="login-fields">
                  {loginError && <span className="error">{error}</span>}
                  <SMLLabel value="Email" style="title2"></SMLLabel>
                  <SMLTextBox
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <span className="button1 error">{emailError}</span>
                  )}
                </div>
                <div className="login-fields">
                  <div className="login-groupby">
                    <label className="title2">Password</label>
                    <SMLLinkButton
                      handleClickEvent={handleForgetPassClick}
                      value={
                        <span className="forgotPassword sub-subtitle3">
                          Forgot Password
                          <BiQuestionMark className="questionMark" />
                        </span>
                      }
                    />
                  </div>
                  <div className="passwordfield">
                    <SMLTextBox
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="toggle-password-visibility-login"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </span>
                  </div>
                  {passwordError && (
                    <span className="button1 error">{passwordError}</span>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {" "}
                    <input
                      style={{
                        height: "14px",
                        width: "14px",
                        cursor: "pointer",
                      }}
                      type="checkbox"
                      id="Remember"
                    ></input>
                    <label
                      className="forgotPassword sub-subtitle3"
                      htmlFor="Remember"
                    >
                      Remember Me
                    </label>
                  </div>
                </div>
              </div>
              <div className="login-controls1">
                <div className="login-fields1">
                  <SMLButton
                    style="SMLButton1"
                    value="Log In"
                    handleClickEvent={handleLogin}
                  />
                  <div className="login-groupby">
                    <div className="div-line"></div>
                    <p className="or">or</p>
                    <div className="div-line"></div>
                  </div>
                </div>
                <div className="login-fields1">
                  <div className="login-groupbyBtns google">
                    <div>
                      <GoogleLogin
                        onSuccess={(codeResponse) => {
                          handleGoogleLogin(codeResponse);
                        }}
                        onError={() => console.log("Login Failed")}
                        setOneTap={false}
                        useOneTap={false}
                        size="large"
                        width="209"
                      />
                    </div>
                    <FacebookLoginButton
                      onSuccess={handleFacebookSuccess}
                      onError={handleFacebookError}
                    />
                    {fbError && <div className="error-message">{fbError}</div>}
                  </div>
                  <div className="login-card-end">
                    <div className="login-groupbyBtns">
                      <span
                        className="sub-subtitle3"
                        style={{ cursor: "pointer" }}
                      >
                        Not a member?
                      </span>

                      <button
                        className="btn-no-margin button1 sub-subtitle3"
                        type="button"
                        onClick={handleSignUpClick}
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Login;
