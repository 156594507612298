import React, { useEffect } from "react";
import ProgressSteps from "../../SignUpPage/ProgressSteps/ProgressSteps";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import SuccessCheckMark from "../../../assets/Images/SuccessCheckmark.svg";
import "./ViewPlan.css";
import { getProAnalysisRequest } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import SMLButton from "../../../components/common/styled-components/SMLButton";
const ViewPlanSuccess = (props) => {
  const trackId = useParams();
  const dispatch = useDispatch();

  return (
    <div>
      <div className="signUp-progress-steps">
        {props.upgradePlan ? (
          <ProgressSteps step="3" upgrade="true" serviceType = {props.specialService} />
        ) : (
          <ProgressSteps step="4" />
        )}
      </div>
      <div className="signUp-close-div" onClick={props.closePopup}>
        <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
      </div>
      <div className="upgrade-welcome-main">
        <div className="welcome-success-checkMark">
          <span className="sml-iconbtn fontcolorblack">
            <img src={SuccessCheckMark} alt="icon" />
          </span>
        </div>
        <div className="Welcome-label-div">
          <div className="Welcome-Label-text Mainheader">
            {props.specialService === "VIDEO_CONSULTATION" || props.specialService === "PAY_PER_TRACK"
              ? "Payment Successful"
              : "Plan upgraded!"}
          </div>
          {/* <div className="Welcome-label-track subtitle3">
            You can now go back to your profile
          </div> */}
          {/* <div className="" style={{margin : "0 auto", width : "100%"}}> */}
          {
            props.specialService === "VIDEO_CONSULTATION" ? 
          <button
            className="button welcome-home-btn"
            type="button1"
            onClick={props.paymentSuccessClick}
          >
            {/* { props.specialService === "VIDEO_CONSULTATION" */}
              Schedule Video Consultation
          </button>
          :
          <button className="button welcome-home-btn1" type="button" onClick={props.closePopup}>Back to Profile</button>
          
          }
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ViewPlanSuccess;
